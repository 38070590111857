import { createApp } from 'vue';
import { MotionPlugin } from '@vueuse/motion';
import { fetchWhoAmI } from './user.js';
import { fetchPresets } from './presets.js';
import { createAppRouter } from './router.js';
import App from './App.vue';
import 'context-filter-polyfill';
import './assets/css/main.scss';

const app = createApp(App)
  .use(MotionPlugin)
  .directive('focus', {
    mounted(el) {
      el.focus();
    }
  });

Promise.all([ fetchPresets(), fetchWhoAmI() ]).then(([ successPresets, successWhoAmI ]) => {
  if (successPresets && successWhoAmI) {
    app.use(createAppRouter()).mount('#app');
    return;
  }

  document!.getElementById('loading-text')!.innerHTML = '<br><br>LOAD FAILED :-(';
  document!.getElementById('loading-text')!.style.display = 'block';
});
