<template>
  <transformable-image
    :touch-move="touchMove"
    :touch-resize="touchResize"
    :mouse-move="mouseMove"
    :wheel-resize="wheelResize"
    :events-filter="eventsFilter"
    :class="isRotating ? 'cursor-grabbing [&>*]:pointer-events-none' : 'cursor-grab'"
    @move="onMove"
    @resize="emit('resize', $event)"
    @transform-end="endRotate"
  >
    <slot />
  </transformable-image>
</template>

<script setup>
import { shallowRef, computed } from 'vue';
import TransformableImage from './components/service/TransformableImage.vue';
import { angle } from '../../crop';

const { image } = defineProps({
  touchMove: {
    type: Boolean,
    required: true
  },
  mouseMove: {
    type: Boolean,
    required: true
  },
  touchResize: {
    type: Boolean,
    required: true
  },
  wheelResize: {
    type: [ Boolean, Object ],
    required: true
  },
  image: {
    type: Object,
    required: true
  }
});

const emit = defineEmits([ 'move', 'resize' ]);

const isRotating = shallowRef(false);
const previousPoint = shallowRef(null);

const imageCenter = computed(() => ({
  x: image.width * image.transforms.scaleX / 2 - image.transforms.translateX,
  y: image.height * image.transforms.scaleY / 2 - image.transforms.translateY
}));

function eventsFilter(nativeEvent, transforming) {
  if (nativeEvent.target.classList.contains('vue-advanced-cropper__foreground')) {
    if ((!transforming && nativeEvent.type === 'mousedown') || nativeEvent.type === 'touchstart') {
      startRotate(nativeEvent);
      return true;
    }

    if (isRotating.value && (nativeEvent.type === 'mousemove' || nativeEvent.type === 'touchmove')) {
      rotate(nativeEvent);
      return false;
    }

    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();

    return false;
  }

  nativeEvent.preventDefault();
  nativeEvent.stopPropagation();
  return true;
}

function startRotate(event) {
  isRotating.value = true;
  previousPoint.value = {
    x: event.clientX,
    y: event.clientY
  };
}

function endRotate() {
  isRotating.value = false;
  previousPoint.value = null;
}

function rotate(event) {
  const x = event.clientX;
  const y = event.clientY;

  if (!previousPoint.value) {
    previousPoint.value = { x, y };
    return;
  }

  const centerX = imageCenter.value.x;
  const centerY = imageCenter.value.y;

  const angle1 = Math.atan2(y - centerY, x - centerX);
  const angle2 = Math.atan2(previousPoint.value.y - centerY, previousPoint.value.x - centerX);

  let newAngleValue = angle.value + ((angle1 - angle2) * 180) / Math.PI;

  if (newAngleValue >= 360) {
    newAngleValue -= 360;

  } else if (newAngleValue <= -360) {
    newAngleValue += 360;
  }

  angle.value = newAngleValue;

  previousPoint.value = { x, y };
}

function onMove(event) {
  if (isRotating.value) {
    return;
  }

  emit('move', event);
}
</script>
