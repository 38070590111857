<template>
  <Switch
    v-model="enabled"
    class="group relative inline-flex flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none"
    :class="isMobile ? 'h-8 w-14' : 'h-3 w-6'"
  >
    <span class="sr-only">Use setting</span>
    <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-full border border-neutral-400" />
    <span aria-hidden="true" :class="[enabled ? 'bg-neutral-400' : 'bg-none', isMobile ? 'h-8 w-14' : 'h-3 w-6', 'pointer-events-none absolute mx-auto rounded-full transition-colors duration-200 ease-in-out']" />
    <span aria-hidden="true" :class="[enabled ? (isMobile? 'translate-x-6' : 'translate-x-3') : 'translate-x-0', isMobile ? 'h-8 w-8' : 'h-3 w-3', 'pointer-events-none absolute left-0 inline-block transform rounded-full bg-primary-500 shadow ring-0 transition-transform duration-200 ease-in-out']" />
  </Switch>
</template>

<script setup lang="ts">
import { Switch } from '@headlessui/vue';
import { useVModel } from '@vueuse/core';
import { isMobile } from '../breakpoints';

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [boolean]
}>();

const enabled = useVModel(props, 'modelValue', emit);
</script>
