<template>
  <modal-draggable :is-open="isOpen">
    <div class="space-y-4 pb-6" :class="isMobile ? '' : 'w-96'">
      <div class="flex items-center px-10 pt-8 mb-4">
        <h2 class="text-info mr-auto text-2xl font-bold">What's new</h2>

        <button-close class="-right-3" @click="gotIt" />
      </div>

      <div class="px-10 pb-2">
        <img :src="imgUrl" alt="&quot;Foix&quot; music video by French 79, Color graded by Sam Zeilender" class="w-full h-full object-cover rounded-xl mt-8 mb-10">

        <template v-for="newsEntry in newsToShow" :key="newsEntry.title">
          <div class="text-pale text-sm mb-2">{{ newsEntry.dateHr }}</div>
          <h4 class="text-xl font-bold mb-2">{{ newsEntry.title }}</h4>
          <p class="mb-10">{{ newsEntry.text }}</p>
        </template>

        <button type="button" class="btn w-full max-w-full text-center" @click="gotIt">Got it!</button>

        <div v-if="isUser" class="text-pale text-xs mt-2 px-2 opacity-70 text-center">
          Next time we'll only show what's new for you.
        </div>
      </div>
    </div>
  </modal-draggable>
</template>

<script setup lang="ts">
import ModalDraggable from './ModalDraggable.vue';
import ButtonClose from './ButtonClose.vue';
import { isMobile } from '../breakpoints';
import imgUrl from '../assets/whats-new.webp';
import { isOpen, news, markAsRead } from '../whatsnew';
import { computed } from 'vue';
import { isUser } from '../user';

const newsToShow = computed(() => {
  if (news.value === null) {
    return [];
  }

  if (isMobile.value) {
    return [ news.value[0] ];
  }

  return news.value;
});

function gotIt() {
  isOpen.value = false;
  if (isUser.value) {
    markAsRead(); // fire-and-forget
  }
}
</script>
