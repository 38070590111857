<template>
  <modal-draggable :is-open="isOpen">
    <div class="space-y-4 pb-6" :class="isMobile ? '' : 'w-72'">
      <modal-header v-if="isMobile" is-bw icon="Glow" title="Film effects" @close="emit('close')" />

      <div v-else class="flex items-center px-6 pt-3 mb-4">
        <h3 class="text-white mr-auto">Film effects</h3>

        <button-close class="-right-3" @click="emit('close')" />
      </div>

      <div class="flex items-center gap-x-4 px-6">
        <slider-range
          key="grain-slider"
          v-model="grain"
          :min="GRAIN_SLIDER_MIN"
          :max="GRAIN_SLIDER_MAX"
          :step="GRAIN_SLIDER_STEP"
          :disabled="!is_grain_enabled"
          label="Grain"
          @dblclick="is_grain_enabled ? setDefault('grain', 'is_grain_enabled') : null"
        />
        <toggle-control key="grain-toggle" v-model="is_grain_enabled" label="Grain" class="shrink-0" />
      </div>

      <div class="flex items-center gap-x-4 px-6">
        <slider-range
          key="bloom-slider"
          v-model="bloom"
          :min="BLOOM_SLIDER_MIN"
          :max="BLOOM_SLIDER_MAX"
          :step="BLOOM_SLIDER_STEP"
          :disabled="!is_bloom_enabled"
          label="Bloom"
          @dblclick="is_bloom_enabled ? setDefault('bloom', 'is_bloom_enabled') : null"
        />
        <toggle-control key="bloom-toggle" v-model="is_bloom_enabled" label="Bloom" class="shrink-0" />
      </div>

      <div class="flex items-center gap-x-4 px-6">
        <slider-range
          key="halation-slider"
          v-model="halation"
          :min="HALATION_SLIDER_MIN"
          :max="HALATION_SLIDER_MAX"
          :step="HALATION_SLIDER_STEP"
          :disabled="!is_halation_enabled"
          label="Halation"
          @dblclick="is_halation_enabled ? setDefault('halation', 'is_halation_enabled') : null"
        />
        <toggle-control key="halation-toggle" v-model="is_halation_enabled" label="Halation" class="shrink-0" />
      </div>

      <div class="flex items-center gap-x-4 px-6">
        <slider-range
          key="vignette-exposure-slider"
          v-model="vignette_exposure"
          :min="VIGNETTE_EXPOSURE_SLIDER_MIN"
          :max="VIGNETTE_EXPOSURE_SLIDER_MAX"
          :step="VIGNETTE_EXPOSURE_SLIDER_STEP"
          :disabled="!is_vignette_enabled"
          label="Vignette exposure"
          @dblclick="is_vignette_enabled ? setDefault('vignette_exposure', 'is_vignette_enabled') : null"
        />
        <toggle-control key="bloom-toggle" v-model="is_vignette_enabled" label="Bloom" class="shrink-0" />
      </div>

      <div class="flex items-center gap-x-4 px-6">
        <slider-range
          key="vignette-size-slider"
          v-model="vignette_size"
          :min="VIGNETTE_SIZE_SLIDER_MIN"
          :max="VIGNETTE_SIZE_SLIDER_MAX"
          :step="VIGNETTE_SIZE_SLIDER_STEP"
          :disabled="!is_vignette_enabled"
          label="Vignette size"
          @dblclick="is_vignette_enabled ? setDefault('vignette_size') : null"
        />
      </div>

      <div class="flex items-center gap-x-4 px-6">
        <slider-range
          key="vignette-feather-slider"
          v-model="vignette_feather"
          :min="VIGNETTE_FEATHER_SLIDER_MIN"
          :max="VIGNETTE_FEATHER_SLIDER_MAX"
          :step="VIGNETTE_FEATHER_SLIDER_STEP"
          :disabled="!is_vignette_enabled"
          label="Vignette Feather"
          @dblclick="is_vignette_enabled ? setDefault('vignette_feather') : null"
        />
      </div>

      <div class="text-center mt-4">
        <button type="button" class="btn-xs text-xs" :disabled="!isResetButtonEnabled" @click="resetAll()">Reset</button>
      </div>
    </div>
  </modal-draggable>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ModalDraggable from './ModalDraggable.vue';
import SliderRange from './SliderRange.vue';
import ToggleControl from './ToggleControl.vue';
import ButtonClose from './ButtonClose.vue';
import ModalHeader from './ModalHeader.vue';
import { isMobile } from '../breakpoints';
import { presets } from '../presets';
import {
  GRAIN_SLIDER_MIN,
  GRAIN_SLIDER_MAX,
  GRAIN_SLIDER_STEP,
  BLOOM_SLIDER_MIN,
  BLOOM_SLIDER_MAX,
  BLOOM_SLIDER_STEP,
  HALATION_SLIDER_MIN,
  HALATION_SLIDER_MAX,
  HALATION_SLIDER_STEP,
  VIGNETTE_EXPOSURE_SLIDER_MIN,
  VIGNETTE_EXPOSURE_SLIDER_MAX,
  VIGNETTE_EXPOSURE_SLIDER_STEP,
  VIGNETTE_SIZE_SLIDER_MIN,
  VIGNETTE_SIZE_SLIDER_MAX,
  VIGNETTE_SIZE_SLIDER_STEP,
  VIGNETTE_FEATHER_SLIDER_MIN,
  VIGNETTE_FEATHER_SLIDER_MAX,
  VIGNETTE_FEATHER_SLIDER_STEP,
} from '../paneConstants';

export type ModalFXStateUpdate = {
  grain?: number;
  bloom?: number;
  halation?: number;
  vignette_exposure?: number;
  vignette_feather?: number;
  vignette_size?: number;
  is_grain_enabled?: boolean;
  is_bloom_enabled?: boolean;
  is_halation_enabled?: boolean;
  is_vignette_enabled?: boolean;
};

type PropertyNames = 'grain' | 'bloom' | 'halation' | 'vignette_exposure' | 'vignette_size' | 'vignette_feather';
type IsEnabledPropertyNames = 'is_grain_enabled' | 'is_bloom_enabled' | 'is_halation_enabled' | 'is_vignette_enabled';

const { state } = defineProps<{
  isOpen: boolean;
  state: any; // FIXME any
}>();

const emit = defineEmits<{
  close: [void]
  update: [ModalFXStateUpdate]
}>();

const is_grain_enabled = computed({
  get() {
    return state.is_grain_enabled;
  },
  set(value) {
    emit('update', { is_grain_enabled: value });
  }
});

const grain = computed({
  get() {
    return state.grain;
  },
  set(value) {
    emit('update', { grain: value });
  }
});

const is_vignette_enabled = computed({
  get() {
    return state.is_vignette_enabled;
  },
  set(value) {
    emit('update', { is_vignette_enabled: value });
  }
});

const vignette_exposure = computed({
  get() {
    return state.vignette_exposure;
  },
  set(value) {
    emit('update', { vignette_exposure: value });
  }
});

const vignette_feather = computed({
  get() {
    return state.vignette_feather;
  },
  set(value) {
    emit('update', { vignette_feather: value });
  }
});

const vignette_size = computed({
  get() {
    return state.vignette_size;
  },
  set(value) {
    emit('update', { vignette_size: value });
  }
});

const is_bloom_enabled = computed({
  get() {
    return state.is_bloom_enabled;
  },
  set(value) {
    emit('update', { is_bloom_enabled: value });
  }
});

const bloom = computed({
  get() {
    return state.bloom;
  },
  set(value) {
    emit('update', { bloom: value });
  }
});


const is_halation_enabled = computed({
  get() {
    return state.is_halation_enabled;
  },
  set(value) {
    emit('update', { is_halation_enabled: value });
  }
});

const halation = computed({
  get() {
    return state.halation;
  },
  set(value) {
    emit('update', { halation: value });
  }
});

const isResetButtonEnabled = computed(() => {
  const defaults = presets.value[state.presetId];

  if (!defaults) {
    return true;
  }

  return (
    (state.grain ?? 0) !== defaults.grain
    || (state.bloom ?? 0) !== defaults.bloom
    || (state.halation ?? 0) !== defaults.halation
    || (state.vignette_exposure ?? 0) !== defaults.vignette_exposure
    || (state.vignette_feather ?? 0) !== defaults.vignette_feather
    || (state.vignette_size ?? 0) !== defaults.vignette_size
    || state.is_grain_enabled !== defaults.is_grain_enabled
    || state.is_bloom_enabled !== defaults.is_bloom_enabled
    || state.is_halation_enabled !== defaults.is_halation_enabled
    || state.is_vignette_enabled
  );
});

function setDefault(propertyName: PropertyNames, isEnabledPropertyName?: IsEnabledPropertyNames) {
  const defaults = presets.value[state.presetId];
  if (!defaults) {
    return;
  }

  let response: ModalFXStateUpdate = {};
  response[propertyName] = defaults[propertyName];
  if (isEnabledPropertyName) {
    response[isEnabledPropertyName] = defaults[isEnabledPropertyName];
  }

  emit('update', response);
}

function resetAll() {
  const defaults = presets.value[state.presetId];

  if (!defaults) {
    return;
  }

  emit('update', {
    grain: defaults.grain,
    bloom: defaults.bloom,
    halation: defaults.halation,
    vignette_exposure: defaults.vignette_exposure,
    vignette_feather: defaults.vignette_feather,
    vignette_size: defaults.vignette_size,
    is_grain_enabled: defaults.is_grain_enabled,
    is_bloom_enabled: defaults.is_bloom_enabled,
    is_halation_enabled: defaults.is_halation_enabled,
    is_vignette_enabled: defaults.is_vignette_enabled
  });
}
</script>
