<template>
  <div class="w-full h-full flex flex-col relative bg-blueprint selectable">
    <template v-if="isLoading">
      Loading...
    </template>

    <template v-else-if="error">
      Failed loading: {{ error }}
    </template>

    <template v-else>
      <div class="bg-gray-900">
        <div class="mx-auto max-w-7xl">
          <div class="bg-gray-900 py-10">
            <div class="px-4 sm:px-6 lg:px-8">
              <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                  <h1 class="text-base font-semibold leading-6 text-white">Webhooks</h1>
                  <p class="mt-2 text-sm text-gray-300">Last {{ limit }} webhooks</p>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button :disabled="isLoading" type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="load">Reload</button>
                </div>
              </div>
              <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-700 tabular-nums">
                      <thead>
                        <tr>
                          <th scope="col" class="py-3.5 text-left text-sm font-semibold text-white">#, When</th>
                          <th scope="col" class="py-3.5 text-left text-sm font-semibold text-white">Who</th>
                          <th scope="col" class="py-3.5 text-left text-sm font-semibold text-white">Event</th>
                          <th scope="col" class="py-3.5 text-left text-sm font-semibold text-white">Details</th>
                        </tr>
                      </thead>

                      <tbody class="divide-y divide-gray-800">
                        <tr v-for="entry in entries" :key="entry.id">
                          <td class="whitespace-nowrap py-4 text-sm text-gray-300">
                            <div>
                              <router-link :to="{ name: 'adminStripeWebhookLogEntryPage', params: { id: entry.id } }" class="link inline-block w-12">#{{ entry.id }}</router-link>
                              <router-link :to="{ name: 'adminStripeWebhookLogEntryPage', params: { id: entry.id } }" class="link">
                                {{ formatDateLocale(entry.createdAt) }}
                              </router-link>
                            </div>
                            <div class="text-gray-500">
                              <span class="inline-block w-12">&nbsp;</span>
                              {{ formatDateUTC(entry.createdAt) }} UTC
                            </div>
                          </td>

                          <td class="whitespace-nowrap py-4 text-sm text-gray-300">
                            <div>
                              <a class="link font-mono" :href="'https://dashboard.stripe.com/customers/' + entry.userStripeCustomerId">{{ entry.userStripeCustomerId }}</a>
                            </div>
                            <div>
                              <router-link v-if="entry.userId" :to="{ name: 'adminUserPage', params: { userIdOrEmail: entry.userId } }" class="link font-mono">{{ entry.userId }}</router-link>
                            </div>
                            <div>
                              {{ entry.userEmail }}
                            </div>
                          </td>
                          <td class="whitespace-nowrap py-4 text-sm text-gray-300">{{ entry.eventType }}</td>
                          <td class="whitespace-nowrap py-4 text-sm text-gray-300">{{ entry.details }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import ky, { HTTPError } from 'ky';
import { formatDateLocale, formatDateUTC } from '../formatters';
import { Ref, ref, ShallowRef, shallowRef } from 'vue';

const limit = 100;
const error: ShallowRef<string | null> = shallowRef('yes');
const isLoading = shallowRef(true);

const entries: Ref<any[] | null> = ref([]);

async function load() {
  entries.value = [];
  error.value = null;

  let json: any;
  try {
    json = await ky.get('/api/admin/stripe-webhook-log', { searchParams: { limit } }).json();
  } catch (e) {
    if (e instanceof HTTPError) {
      if (e.response.status === 401) {
        error.value = "Unauthorized";
        return;
      }
    }
    error.value = String(e);

  } finally {
    isLoading.value = false;
  }

  if (!json?.success) {
    error.value = json?.error || 'Unknown error';
    entries.value = null;
    return;
  }

  entries.value = json.entries;
}

load();
</script>
