<template>
  <div
    v-if="!isMobile || !isSettingsModalOpen && !isFXModalOpen && !isMainMenuOpen && !isWhatsNewOpen"
    class="shrink-0 bg-main text-white/60 shadow-lg z-20"
    :class="{ 'toolbar-sm': isMobile }"
  >
    <modal-header v-if="isDropzoneVisible" icon="Add" title="Upload image" :is-mobile="isMobile" is-bw :disabled="uploader?.isUploading" @close="toggleUpload" />

    <modal-header
      v-else-if="isPaneExpanded"
      :icon="getIconByMode(paneMode!)"
      :title="pageTitle"
      :is-mobile="isMobile"
      is-bw
      @close="onButtonCloseClicked"
    >
      <div
        v-if="!isMobile && (state.presetId ?? null) === null"
        class="flex justify-center items-center text-sm font-light pointer-events-none text-left mr-auto"
      >
        <div class="inline-flex justify-center text-left rounded-2xl items-center bg-transparent">
          <icon-info class="w-8 h-8 inline-block shrink-0 -my-1.5" />
          Scroll or pinch to zoom, drag to navigate. Pick your poison.
        </div>
      </div>

      <div v-if="isExportPage" class="text-sm font-light pointer-events-none mr-auto truncate">
        {{ presetTitle }}
      </div>
    </modal-header>

    <div v-else class="px-1 xs:px-4 md:py-3">
      <div class="flex xs:gap-x-1">
        <tool-bar-button :icon="!isUser ? 'Menu' : isActive ? 'UserProfileSubscribed' : 'UserProfile'" class="main-menu-button !items-center" icon-class="!mt-0" @click="toggleMainMenu" />

        <div v-if="isMobile" class="inline-flex items-center mr-auto px-1 xs:pr-6 h-12">
          <the-logo class="h-8 md:h-10" />
        </div>

        <div v-else class="inline-flex items-center mr-auto px-1 pr-6 h-16 md:h-[76px]">
          <the-logo-text class="h-8 md:h-10" />
        </div>

        <template v-if="!isMobile">
          <tool-bar-button :disabled="isAllDisabled" label="Presets" icon="Presets" @click="(state.presetId ?? null) !== null ? emit('togglePane', 'preset') : false" />
          <tool-bar-button :disabled="isAllDisabled" label="Light" icon="Light" @click="emit('togglePane', 'light')" />
          <tool-bar-button :disabled="isAllDisabled" label="Color" icon="Color" @click="emit('togglePane', 'color')" />
          <tool-bar-button :disabled="isAllDisabled" label="Effects" icon="Glow" :active="isFXModalOpen" :dotted="isAnyFxChanged" @click="emit('openFXModal')" />
        </template>

        <div class="ml-auto" />

        <tool-bar-button v-if="!isMobile" :disabled="isAllDisabled" class="hidden 2xs:inline-flex" :label="isMobile ? undefined : 'Crop'" icon="Crop" @click="goToCrop" />
        <tool-bar-button :disabled="isAllDisabled" class="hidden 2xs:inline-flex" :label="isMobile ? undefined : 'Original'" :active="isOriginalShown" :icon="isOriginalShown ? 'PreviewOff' : 'PreviewOn'" @click="emit('toggleOriginal')" />
        <tool-bar-button :disabled="isAllDisabled" class="hidden 2xs:inline-flex" :label="isMobile ? undefined : 'Fit / Fill'" :active="fitMode === 'cover'" icon="FitFill" @click="emit('toggleFit')" />
        <tool-bar-button :disabled="isAllDisabled" :label="isMobile ? undefined : 'Adjust'" icon="Settings" :active="isSettingsModalOpen" @click="emit('openSettingsModal')" />
        <tool-bar-button :disabled="isAllDisabled || uploader?.isUploading" icon="Add" :label="isMobile ? undefined : 'Upload'" :active="isDropzoneVisible" @click="toggleUpload" />
        <tool-bar-button :disabled="isAllDisabled" :label="isMobile ? undefined : 'Export'" icon="Download" @click="emit('exportImage')" />
      </div>
    </div>
  </div>

  <teleport v-if="!isAllDisabled" to="body">
    <div
      class="fixed inset-0 overflow-auto"
      :class="[ isDropzoneVisible ? 'z-10' : '-z-10' ]"
    >
      <div class="flex flex-col bg-blueprint justify-center items-center min-w-full min-h-[max(100%,600px)]">
        <div class="w-5/6 max-w-md my-[120px]">
          <file-uploader ref="uploader" :drop-target="bodyElement" @uploaded="onFileUploaded" />
        </div>
      </div>
    </div>
  </teleport>

  <div class="grow relative overflow-hidden">
    <modal-whats-new />

    <modal-menu ref="mainMenu" :is-open="isMainMenuOpen" @close="isMainMenuOpen = false" />
    <div class="w-full h-full overflow-auto">
      <slot />
    </div>
  </div>

  <template v-if="isMobile && !isPaneExpanded && !isMainMenuOpen && !isDropzoneVisible">
    <div v-if="isImagePage" class="shrink-0 z-20 text-center bg-neutral-900/50 truncate px-4 py-1 text-white/40" :class="presetTitleClass">
      {{ presetTitle }}
    </div>
    <div class="shrink-0 bg-neutral-900/70 text-white/60 backdrop-blur shadow-lg px-4 xs:px-4 py-1 z-20 pb-4">
      <div class="flex items-center justify-between xs:justify-center xs:gap-x-4">
        <tool-bar-button :disabled="isAllDisabled" label="Presets" icon="Presets" @click="(state.presetId ?? null) !== null ? emit('togglePane', 'preset') : false" />
        <tool-bar-button :disabled="isAllDisabled" label="Light" icon="Light" @click="emit('togglePane', 'light')" />
        <tool-bar-button :disabled="isAllDisabled" label="Color" icon="Color" @click="emit('togglePane', 'color')" />
        <tool-bar-button :disabled="isAllDisabled" label="Effects" icon="Glow" :active="isFXModalOpen" :dotted="isAnyFxChanged" @click="emit('openFXModal')" />
      </div>
    </div>
  </template>

  <div v-if="isMobile && isPaneExpanded && (state.presetId ?? null) === null" class="shrink-0 bg-neutral-900/70 text-white/60 backdrop-blur shadow-lg px-1 xs:px-4 py-1 z-20">
    <div class="inline-flex justify-center items-start text-sm pt-1">
      <icon-info class="w-8 h-8 inline-block shrink-0 -my-1.5" />
      Scroll or pinch to zoom, drag to navigate. Pick your poison.
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, Ref } from 'vue';
import ToolBarButton from './ToolBarButton.vue';
import TheLogo from './icons/TheLogo.vue';
import TheLogoText from './icons/TheLogoText.vue';
import IconInfo from './icons/IconInfo.vue';
import ModalMenu from './ModalMenu.vue';
import ModalWhatsNew from './ModalWhatsNew.vue';
import ModalHeader from './ModalHeader.vue';
import { isMobile } from '../breakpoints';
import { useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import { isUser, isActive } from '../user';
import { presets } from '../presets';
import FileUploader from './FileUploader.vue';
import { PaneMode, ScrollPaneFitMode } from '../paneConstants';
import { isOpen as isWhatsNewOpen } from '../whatsnew';

const emit = defineEmits<{
  togglePane: [PaneMode];
  openSettingsModal: [void];
  openFXModal: [void];
  toggleFit: [void];
  exportImage: [void];
  toggleOriginal: [void];
  toggleMainMenu: [boolean];
}>();

type ToolbarPaneMode = PaneMode | 'EXPORT';

const { paneMode, state, isOriginalShown } = defineProps<{
  paneMode?: ToolbarPaneMode;
  fitMode?: ScrollPaneFitMode;
  state?: any;
  isPaneExpanded?: boolean;
  isSettingsModalOpen?: boolean;
  isFXModalOpen?: boolean;
  isOriginalShown?: boolean;
  isAllDisabled?: boolean;
}>();

const titleByMode: Record<ToolbarPaneMode, string> = {
  preset: 'Film presets',
  light: 'Brightness / Contrast',
  color: 'Temperature / Tint',
  EXPORT: 'Export',
  settings: ''
};

// export title should be hidden on mobile
const pageTitle = computed(() => {
  if (isMobile.value && paneMode === 'EXPORT') {
    return '';
  }

  return titleByMode[paneMode!] || '';
});

const iconByMode: Record<ToolbarPaneMode, string> = {
  preset: 'Presets',
  light: 'Light',
  color: 'Color',
  EXPORT: 'Download',
  settings: ''
};

function getIconByMode(mode: ToolbarPaneMode) {
  return iconByMode[mode] || '';
}

const bodyElement: HTMLElement = document.querySelector('body')!;

const router = useRouter();
const uploader: Ref<typeof FileUploader | null> = ref(null);
const isDropzoneVisible = ref(false);

const mainMenu = ref(null);
const isMainMenuOpen = ref(false);

onClickOutside(mainMenu, (event: PointerEvent) => {
  // @ts-ignore DOM pointer event is weird
  if (event.target.closest('.main-menu-button') !== null) {
    return;
  }
  isMainMenuOpen.value = false;
});

const isAnyFxChanged = computed(() => {
  const id = state?.presetId;
  const preset = presets.value?.[id];
  if (!preset) {
    return false;
  }

  return preset.is_grain_enabled !== state.is_grain_enabled
    || preset.is_vignette_enabled !== state.is_vignette_enabled
    || preset.is_bloom_enabled !== state.is_bloom_enabled
    || preset.is_halation_enabled !== state.is_halation_enabled
    || (preset.is_grain_enabled && preset.grain !== state.grain)
    || (preset.is_bloom_enabled && preset.bloom !== state.bloom)
    || (preset.is_halation_enabled && preset.halation !== state.halation)
    || (preset.is_vignette_enabled && (
      preset.vignette_size !== state.vignette_size
      || preset.vignette_exposure !== state.vignette_exposure
      || preset.vignette_feather !== state.vignette_feather
    ));
});

const isImagePage = computed(() => router.currentRoute.value.name === 'imagePage');
const isExportPage = computed(() => router.currentRoute.value.name === 'exportPage');

const presetTitle = computed(() => {
  if (isOriginalShown) {
    return 'Original';
  }

  const id = state.presetId;

  if (!presets.value?.[id]) {
    return '(unknown)';
  }

  return presets.value?.[id].caption;
});

const presetTitleClass = computed(() => {
  if (isMobile.value) {
    return 'text-xs font-extralight';
  }

  return 'text-sm font-light';
});

watch(() => uploader.value?.isUploading, isUploading => {
  if (isUploading) {
    isDropzoneVisible.value = true;
  }
});

watch(() => uploader.value?.isDragging, (newVal, oldVal) => {
  if (oldVal === undefined) {
    return;
  }

  if (!newVal) {
    return;
  }

  isDropzoneVisible.value = true;
});

function toggleUpload() {
  isDropzoneVisible.value = !isDropzoneVisible.value;
}

function onFileUploaded(imageId: string) {
  router.push({ name: 'imagePage', query: { i: imageId } });
}

function onButtonCloseClicked() {
  if ((state.presetId ?? null) === null) {
    router.push({ name: 'uploadPage' });
    return;
  }

  emit('togglePane', paneMode as PaneMode);
}

function toggleMainMenu() {
  isMainMenuOpen.value = !isMainMenuOpen.value;
  emit('toggleMainMenu', isMainMenuOpen.value);
}

function goToCrop() {
  router.push({ name: 'cropPage', query: { i: state.imageId } });
}
</script>
