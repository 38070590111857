<template>
  <modal-draggable :is-open="isOpen!">
    <div class="space-y-4 pb-6" :class="isMobile ? '' : 'w-auto cropper-vertical:w-72'">
      <modal-header v-if="isMobile" is-bw icon="Ratio" title="Crop ratio" @close="emit('close')" />

      <div v-else class="flex items-center px-6 pt-3 mb-4">
        <h3 class="text-white mr-auto">Crop ratio</h3>

        <button-close class="-right-3" @click="emit('close')" />
      </div>

      <div class="px-6 grid grid-cols-6 cropper-vertical:grid-cols-2">
        <cropper-button
          v-for="ratio in ratios"
          :key="ratio.label"
          :label="ratio.label"
          :description="ratio.description"
          :icon="ratio.icon"
          class="mx-auto mb-8"
          :class="aspectRatio === ratio.value ? 'saturate-0 brightness-150' : ''"
          @click="onClick(ratio)"
        />
      </div>
    </div>
  </modal-draggable>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from 'vue';
import ModalDraggable from './ModalDraggable.vue';
import ButtonClose from './ButtonClose.vue';
import ModalHeader from './ModalHeader.vue';
import { isMobile } from '../breakpoints';
import CropperButton from './CropperButton.vue';

type Ratio = {
  label: string;
  description?: string;
  value?: number;
  icon: string;
};

const { imageAspectRatio } = defineProps<{
  isOpen?: boolean;
  aspectRatio?: number;
  imageAspectRatio?: number;
}>();

const emit = defineEmits<{
  close: [void]
  update: [number?]
}>();

const ratios: ComputedRef<Ratio[]> = computed(() => [
  { label: 'Freeform', value: undefined, icon: 'RatioFreeform' },
  { label: 'Original', value: imageAspectRatio, icon: 'RatioOriginal' },
  { label: '5:4', description: 'IG Post', value: 5 / 4, icon: 'Ratio5x4' },
  { label: '4:5', description: 'IG Post', value: 4 / 5, icon: 'Ratio4x5' },
  { label: '4:3', description: 'Mobile', value: 4 / 3, icon: 'Ratio4x3' },
  { label: '3:4', description: 'Mobile', value: 3 / 4, icon: 'Ratio3x4' },
  { label: '3:2', description: '35mm photo', value: 3 / 2, icon: 'Ratio3x2' },
  { label: '2:3', description: '35mm photo', value: 2 / 3, icon: 'Ratio2x3' },
  { label: '16:9', description: 'Cinema', value: 16 / 9, icon: 'Ratio16x9' },
  { label: '9:16', description: 'IG Stories', value: 9 / 16, icon: 'Ratio9x16' },
  { label: '2.35:1', description: 'Wide Screen', value: 235 / 100, icon: 'Ratio235x1' },
  { label: '1:1', description: 'Square', value: 1, icon: 'Ratio1x1' }
]);

function onClick(ratio: Ratio) {
  emit('update', ratio.value);
  emit('close');
}
</script>
