<template>
  <div
    ref="$el"
    class="pinch-scroll-zoom"
    :class="componentClass"
    :style="componentStyle"
    @mousedown.prevent="startMouseDrag"
    @mousemove.prevent="doMouseDrag"
    @touchstart.prevent="startTouchDrag"
    @touchmove.prevent="doTouchDrag"
    @wheel.prevent="doWheelScale"
  >
    <div
      ref="content"
      class="pinch-scroll-zoom__content"
      :style="containerStyle"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
import { throttle, debounce } from 'lodash';
import PinchScrollZoomAxis from './pinch-scroll-zoom-axis';
import {
  computed,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch
} from 'vue';
import { getDistance } from './pinch-scroll-zoom-ext';

const {
  scale,
  throttleDelay,
  width,
  height,
  originX,
  originY,
  translateX,
  translateY,
  contentWidth,
  contentHeight,
  draggable,
  minScale,
  maxScale,
  within,
  wheelVelocity
} = defineProps({
  contentWidth: {
    type: Number,
    required: true
  },
  contentHeight: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  originX: {
    type: Number,
    required: true
  },
  originY: {
    type: Number,
    required: true
  },
  translateX: {
    type: Number,
    default: 0
  },
  translateY: {
    type: Number,
    default: 0
  },
  scale: {
    type: Number,
    default: 1
  },
  throttleDelay: {
    type: Number,
    default: 25
  },
  within: {
    type: Boolean,
    default: true
  },
  minScale: {
    type: Number,
    default: 0.3
  },
  maxScale: {
    type: Number,
    default: 5
  },
  wheelVelocity: {
    type: Number,
    default: 0.001
  },
  draggable: {
    type: Boolean,
    default: true
  }
});

const $el = ref(null);

const throttleDoDrag = throttle(doDragEvent, throttleDelay);
const stopScaling = debounce(doStopScalingEvent, 200);

const state = reactive({
  touch1: false,
  touch2: false,
  currentScale: scale,
  startScale: scale,
  zoomIn: false,
  zoomOut: false,
  axisX: new PinchScrollZoomAxis({
    size: width,
    origin: originX,
    translate: translateX,
    contentSize: contentWidth
  }),
  axisY: new PinchScrollZoomAxis({
    size: height,
    origin: originY,
    translate: translateY,
    contentSize: contentHeight
  })
});

const componentClass = computed(() => ({
  'pinch-scroll-zoom--zoom-out': state.zoomOut,
  'pinch-scroll-zoom--zoom-in': state.zoomIn
}));

const componentStyle = computed(() => ({
  width: `${width}px`,
  height: `${height}px`
}));

const containerStyle = computed(() => {
  const transform = `translate(${state.axisX.point}px, ${state.axisY.point}px) scale(${state.currentScale})`;
  const transformOrigin = `${state.axisX.origin}px ${state.axisY.origin}px`;

  return { transform, transformOrigin };
});

const emit = defineEmits([
  'stopDrag',
  'startDrag',
  'dragging',
  'scaling'
]);

defineExpose({
  $el,
  setData
});

function setData(data) {
  state.currentScale = data.scale;
  state.axisX.setPoint(data.translateX);
  state.axisY.setPoint(data.translateY);
  state.axisX.setOrigin(data.originX);
  state.axisY.setOrigin(data.originY);
  checkWithin();
  emit('stopDrag', getEmitData());
}

function getEmitData() {
  return {
    x: state.axisX.point,
    y: state.axisY.point,
    scale: state.currentScale,
    originX: state.axisX.origin,
    originY: state.axisY.origin,
    translateX: state.axisX.point,
    translateY: state.axisY.point
  };
}

function stopDrag() {
  state.touch1 = false;
  state.touch2 = false;
  state.zoomIn = false;
  state.zoomOut = false;
  emit('stopDrag', getEmitData());
}

function startMouseDrag(mouseEvent) {
  if (!draggable) return;
  const touches = [
    {
      clientX: mouseEvent.clientX,
      clientY: mouseEvent.clientY
    }
  ];
  startDrag(touches);
}

function startTouchDrag(touchEvent) {
  if (!draggable) return;
  const touches = Array.from(touchEvent.touches);
  startDrag(touches);
}

function startDrag(touches) {
  if (touches.length === 0) {
    stopDrag();
    return;
  }
  const clientX1 = getBoundingTouchClientX(touches[0]);
  const clientY1 = getBoundingTouchClientY(touches[0]);
  if (touches.length > 1) {
    state.touch1 = true;
    state.touch2 = true;
    state.startScale = state.currentScale;

    const clientX2 = getBoundingTouchClientX(touches[1]);
    const clientY2 = getBoundingTouchClientY(touches[1]);

    state.axisX.pinch(clientX1, clientX2, state.currentScale);
    state.axisY.pinch(clientY1, clientY2, state.currentScale);
  } else {
    state.touch1 = true;
    state.touch2 = false;
    state.axisX.touch(clientX1);
    state.axisY.touch(clientY1);
  }

  emit('startDrag', getEmitData());
}

function doDragEvent(touches) {
  if (!state.touch1 && !state.touch2) return;
  // if (!touchEvent.touches) {
  //   touchEvent.touches = [
  //     {
  //       clientX: touchEvent.clientX,
  //       clientY: touchEvent.clientY,
  //     },
  //   ];
  // }
  if (touches.length === 0) return;

  if (state.touch1 && state.touch2 && touches.length === 1) startDrag(touches);

  if (!state.touch1 || (!state.touch2 && touches.length === 2)) { startDrag(touches); }

  if (state.touch1 && state.touch2) {
    state.axisX.dragPinch(
      getBoundingTouchClientX(touches[0]),
      getBoundingTouchClientX(touches[1])
    );
    state.axisY.dragPinch(
      getBoundingTouchClientY(touches[0]),
      getBoundingTouchClientY(touches[1])
    );
  } else {
    state.axisX.dragTouch(getBoundingTouchClientX(touches[0]));
    state.axisY.dragTouch(getBoundingTouchClientY(touches[0]));
  }

  doScale(touches);
  submitDrag();
}

function submitDrag() {
  emit('dragging', getEmitData());
}

function doStopScalingEvent() {
  state.zoomIn = false;
  state.zoomOut = false;
}

function getBoundingTouchClientX(touch) {
  return touch.clientX - $el.value.getBoundingClientRect().left;
}

function getBoundingTouchClientY(touch) {
  return touch.clientY - $el.value.getBoundingClientRect().top;
}

function doScale(touches) {
  if (touches.length < 2 || !state.touch1 || !state.touch2) {
    checkWithin();
    return;
  }

  const touch1 = touches[0];
  const touch2 = touches[1];

  const distance = getDistance(
    getBoundingTouchClientX(touch1),
    getBoundingTouchClientY(touch1),
    getBoundingTouchClientX(touch2),
    getBoundingTouchClientY(touch2)
  );

  const startDistance = getDistance(
    state.axisX.start1,
    state.axisY.start1,
    state.axisX.start2,
    state.axisY.start2
  );

  const _scale = state.startScale * (distance / startDistance);
  submitScale(_scale);
}

function submitScale(_scale) {
  if (
    (_scale >= minScale || state.currentScale < _scale)
    && (_scale <= maxScale || state.currentScale > _scale)
  ) {
    if (state.currentScale !== _scale) {
      state.zoomIn = state.currentScale < _scale;
      state.zoomOut = state.currentScale > _scale;
      state.currentScale = _scale;
      stopScaling();
    }
  }
  checkWithin();
  emit('scaling', getEmitData());
}

function checkWithin() {
  if (!within) {
    return;
  }

  state.axisY.checkAndResetToWithin(state.currentScale);
  state.axisX.checkAndResetToWithin(state.currentScale);
}

function doMouseDrag(mouseEvent) {
  if (!draggable) return;
  const touches = [
    {
      clientX: mouseEvent.clientX,
      clientY: mouseEvent.clientY
    }
  ];
  throttleDoDrag(touches);
}

function doTouchDrag(touchEvent) {
  if (!draggable) return;
  const touches = Array.from(touchEvent.touches);
  throttleDoDrag(touches);
}

function doWheelScale(event) {
  const clientX = getBoundingTouchClientX(event);
  const clientY = getBoundingTouchClientY(event);
  state.axisX.pinch(clientX, clientX, state.currentScale);
  state.axisY.pinch(clientY, clientY, state.currentScale);

  const factor = 1 - event.deltaY * wheelVelocity;
  const _scale = state.currentScale * factor;
  submitScale(_scale);
}

onMounted(() => {
  window.addEventListener('mouseup', stopDrag);
});

onBeforeUnmount(() => {
  window.removeEventListener('mouseup', stopDrag);
});

watch(() => scale, submitScale);
watch(
  () => translateX,
  v => state.axisX.setPoint(v)
);
watch(
  () => translateY,
  v => state.axisY.setPoint(v)
);
watch(
  () => originX,
  val => state.axisX.setOrigin(val ?? 0)
);
watch(
  () => originY,
  val => state.axisY.setOrigin(val ?? 0)
);
watch(
  () => within,
  () => checkWithin()
);
watch(
  () => width,
  val => state.axisX.setSize(val ?? 0)
);
watch(
  () => height,
  val => state.axisY.setSize(val ?? 0)
);
watch(
  () => contentWidth,
  val => state.axisX.setContentSize(val ?? 0)
);
watch(
  () => contentHeight,
  val => state.axisY.setContentSize(val ?? 0)
);
</script>

<style lang="scss">
.pinch-scroll-zoom {
  position: relative;
  touch-action: none;
  user-select: none;
  user-zoom: none;
  overflow: visible;
  :active {
    cursor: all-scroll;
  }

  &--zoom-in {
    cursor: zoom-in;
  }

  &--zoom-out {
    cursor: zoom-out;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
    }
  }
}
</style>
