import { MoveEvent } from '../events';
import { Coordinates } from '../typings';
import { applyMove, fit } from '../service';

export interface MoveParams {
	event: MoveEvent;
	coordinates: Coordinates;
}

export function move(params: MoveParams): Coordinates {
	const { event, coordinates } = params;

	const movedCoordinates = applyMove(coordinates, event.directions);

	return applyMove(movedCoordinates, fit(movedCoordinates, {}));
}
