<template>
  <div
    class="relative w-full h-full object-contain group cursor-pointer"
    @mousedown="onMousedown"
    @mouseup="onMouseup"
    @touchstart="onMousedown"
    @touchend="onMouseup"
  >
    <img class="min-w-full min-h-full bg-neutral-900/50" :style="imageStyle" :src="imgSrc" alt="">
    <div
      class="absolute inset-0 origin-top-left shadow-lg overflow-hidden pointer-events-none transition-shadow duration-300"
      :class="[
        isPaneExpanded ? 'rounded-[16px] group-hover:border-[#96928B] group-hover:border-2' : '',
        isCurrentPreset && isPaneExpanded ? 'border-primary-500 border-2' : '',
        isMobile && !isPaneExpanded ? 'hidden' : ''
      ]"
      :style="style"
    >
      <div
        v-if="title"
        class="absolute px-4 py-2 text-center text-sm bg-neutral-900/70 text-white/40 truncate"
        :class="[
          titleClass,
          isPaneExpanded ? 'group-hover:text-white/80 group-hover:-inset-x-0.5 group-hover:-bottom-0.5' : '',
          isCurrentPreset && isPaneExpanded ? '-inset-x-0.5 -bottom-0.5' : 'inset-x-0 bottom-0'
        ]"
      >
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, Ref } from 'vue';
import { isMobile } from '../breakpoints';

const blurAmount = window.safari ? 0.001 : 0.7;

const CLICK_DELTA = 5;

const {
  imageWidth = 400,
  imageHeight = 300,
  scale = 1,
  imageUrl,
  placeholderUrl,
  isPaneExpanded = false,
  translateX = 0,
  translateY = 0,
  isCurrentPreset = false
} = defineProps<{
  imageUrl?: string;
  placeholderUrl: string;
  title: string;
  isHighlighted: boolean;
  imageWidth: number;
  imageHeight: number;
  scale: number;
  translateX: number;
  translateY: number;
  isPaneExpanded: boolean;
  isCurrentPreset: boolean;
}>();

const emit = defineEmits<{
  clicked: [string]
}>();

type SavedPosition = {
  x: number | null;
  y: number | null;
};

const savedPosition: Ref<SavedPosition> = ref({ x: null, y: null });

const sizeX = computed(() => (imageWidth * scale));
const sizeY = computed(() => (imageHeight * scale));

const imgSrc: Ref<string> = ref('');

watch(() => imageUrl, url => {
  if (!url) {
    if (!imgSrc.value) {
      imgSrc.value = placeholderUrl;
    }
    return;
  }

  // we don't need smart placeholder in expanded mode
  if (isPaneExpanded) {
    imgSrc.value = url;
    return;
  }

  const image = new Image();
  image.onload = () => {
    if (imageUrl !== url) {
      return;
    }
    imgSrc.value = url;
  };
  image.src = url;
}, { immediate: true });

const style = computed(() => `transform: scale(${1 / scale}); width: ${sizeX.value}px; height: ${sizeY.value}px;`);

const borderRadius = computed(() => (isPaneExpanded ? (16 / scale) + 'px' : 0));

const imageStyle = computed(() => {
  // const contrast = 1;

  const blur = imageUrl === imgSrc.value ? 0 : blurAmount;

  // NOTE: left here in case we will need to change contrast for grain preview
  // if (props.grain > 80) {
  //   const scale = (props.grain - 80) / 20
  //   contrast = 1 + scale * 0.5;
  //   console.log('contrast', contrast)
  // }

  // add `contrast(${contrast})` to filter

  return { filter: `blur(${blur}px)`, borderRadius: borderRadius.value };
});

const titleClass = computed(() => {
  if (scale > 1.5) {
    return 'text-normal';
  }
  if (scale > 1) {
    return 'text-sm';
  }
  if (scale > 0.5) {
    return 'text-xs';
  }
  return 'hidden';
});

function onMousedown() {
  savedPosition.value = { x: translateX, y: translateY };
}

function onMouseup() {
  if (Math.abs(savedPosition.value.x! - translateX) > CLICK_DELTA || Math.abs(savedPosition.value.y! - translateY) > CLICK_DELTA) {
    return;
  }

  onClick();
}

function onClick() {
  emit('clicked', imageUrl!);
}
</script>
