import { createWebHistory, createRouter, isNavigationFailure } from 'vue-router';
import PricesPage from './pages/PricesPage.vue';
import UploadPage from './pages/UploadPage.vue';
import LoaderPage from './pages/LoaderPage.vue';
import ImagePage from './pages/ImagePage.vue';
import ExportPage from './pages/ExportPage.vue';
import CropPage from './pages/CropPage.vue';
import StripeSuccessPage from './pages/StripeSuccessPage.vue';
import AdminUserPage from './pages/AdminUserPage.vue';
import AdminStripeWebhookLogEntryPage from './pages/AdminStripeWebhookLogEntryPage.vue';
import AdminStripeWebhookLogListPage from './pages/AdminStripeWebhookLogListPage.vue';

export function createAppRouter() {
  const routes = [
    {
      path: '/',
      component: UploadPage,
      name: 'uploadPage',
      meta: {
        title: "Upload photo"
      }
    },
    {
      path: '/prices',
      component: PricesPage,
      name: 'pricesPage',
      meta: {
        title: "Prices"
      }
    },
    {
      path: '/stripe-success/',
      component: StripeSuccessPage,
      name: 'stripeSuccessPage',
      props: (route: any) => ({ sessionId: route.query.sessionId }),
      meta: {
        title: "Loading payment status"
      }
    },
    {
      path: '/i/',
      component: LoaderPage,
      props: (route: any) => ({ imageId: route.query.i }),
      meta: {
        title: "Photo"
      },
      children: [
        {
          path: '',
          component: ImagePage,
          name: 'imagePage',
          props: true
        },
        {
          path: '',
          component: ExportPage,
          name: 'exportPage',
          props: true
        },
        {
          path: '',
          component: CropPage,
          name: 'cropPage',
          props: true
        }
      ]
    },

    {
      path: '/admin/user/:userIdOrEmail',
      component: AdminUserPage,
      name: 'adminUserPage',
      props: true
    },
    {
      path: '/admin/stripe-webhook-log/:id',
      component: AdminStripeWebhookLogEntryPage,
      name: 'adminStripeWebhookLogEntryPage',
      props: true
    },
    {
      path: '/admin/stripe-webhook-log/',
      component: AdminStripeWebhookLogListPage,
      name: 'adminStripeWebhookLogListPage',
      props: true
    }
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
      // always scroll to top
      return { top: 0 };
    }
  });

  router.afterEach((to, _, failure) => {
    if (isNavigationFailure(failure)) {
      return;
    }

    let title: string = '';

    if (to.fullPath.startsWith('/admin/')) {
      title = "Admin";
    } else {
      title = to.meta?.title as string || '';
    }

    document.title = [ "Dehancer Online", title ].join(': ');

    /*
    const fakeUrl = '/' + to.name;

    window.dataLayer.push({
      event: 'pageView',
      pageUrl: fakeUrl,
      pageTitle: fakeUrl
    });

    window._paq.push([ 'setCustomUrl', fakeUrl ]);
    window._paq.push([ 'setDocumentTitle', fakeUrl ]);
    window._paq.push([ 'deleteCustomVariables', 'page' ]);
    window._paq.push([ 'deleteCustomDimension', 1 ]);

    if (from) {
      window._paq.push([ 'setReferrerUrl', window.location.origin + '/' + from.name ]);
    }

    window._paq.push([ 'trackPageView' ]);

    try {
      gtag('event', 'page_view', {
        page_title: fakeUrl,
        page_location: fakeUrl
      });
    } catch {} // ignored
    */
  });

  return router;
}
