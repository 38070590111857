import { useStorage } from '@vueuse/core';

export type DehancerImageState = {
  imageId: string;
  presetId?: number;
  tint: number;
  temperature: number;
  contrast: number;
  exposure: number;
  color_boost: number;
  is_grain_enabled: boolean;
  grain: number;
  is_bloom_enabled: boolean;
  bloom: number;
  is_halation_enabled: boolean;
  halation: number;
  is_vignette_enabled: boolean;
  vignette_exposure: number;
  vignette_feather: number;
  vignette_size: number;
}

export type DehancerImageStateByImageId = Record<string, DehancerImageState>;

export const stateByImageId = useStorage<DehancerImageStateByImageId>('stateByImageId', {});

export function clear(preserveImageId: string) {
  if (!preserveImageId) {
    stateByImageId.value = {};
    return;
  }

  const state = stateByImageId.value[preserveImageId];
  stateByImageId.value = {};
  stateByImageId.value[preserveImageId] = state;
}
