<template>
  <div
    ref="zoomerContainer"
    class="w-full h-full absolute inset-0 overflow-hidden"
    :class="{ 'pointer-events-none': isAnimating }"
    :style="containerStyle"
  >
    <PinchScrollZoom
      ref="zoomer"
      :width="width"
      :height="height"
      :content-width="paneSizeX"
      :content-height="paneSizeY"
      :within="!disabled"
      :min-scale="minScale"
      :max-scale="maxScale"
      :scale="scale"
      :origin-x="originX"
      :origin-y="originY"
      :translate-x="translateX"
      :translate-y="translateY"
      :wheel-velocity="0.003"
      @scaling="onEvent($event, 'scaling')"
      @start-drag="onEvent($event, 'startDrag')"
      @stop-drag="onEvent($event, 'stopDrag')"
      @dragging="onEvent($event, 'dragging')"
    >
      <slot
        :state="state"
        :center="center"
        :set-state="setState"
      />
    </PinchScrollZoom>
  </div>
</template>

<script setup lang="ts">
// @ts-ignore PinchScrollZoom
import PinchScrollZoom from './vue-pinch-scroll-zoom/pinch-scroll-zoom.vue';
import { ref, reactive, computed, watch, Ref } from 'vue';
import { useElementSize, useTransition, useRafFn, TransitionPresets, MaybeElement } from '@vueuse/core';
import { isMobile } from '../breakpoints';
import { PinchScrollZoomEmitData, ScrollPaneFitMode } from '../paneConstants';

const {
  imageMode = 'contain',
  disabled,
  mainImageX,
  mainImageY,
  paneSizeX = 3000,
  paneSizeY = 3000,
  imageSizeX = 128,
  imageSizeY = 128
} = defineProps<{
  imageMode: ScrollPaneFitMode,
  disabled: boolean;
  mainImageX?: number;
  mainImageY?: number;
  paneSizeX: number;
  paneSizeY: number;
  imageSizeX: number;
  imageSizeY: number;
}>();

defineExpose({ reset, center, setState });

const emit = defineEmits<{
  scaling: [PinchScrollZoomEmitData],
  startDrag: [PinchScrollZoomEmitData],
  stopDrag: [PinchScrollZoomEmitData],
  dragging: [PinchScrollZoomEmitData]
}>();

const containerStyle = computed(() => ({
  paddingTop: isMobile.value ? '48px' : '100px', // top toolbar height
  paddingBottom: isMobile.value ? '72px' : 0 // bottom toolbar height
}));

const zoomer: Ref<typeof PinchScrollZoom | null> = ref(null);
const zoomerContainer: Ref<MaybeElement> = ref(null);
const isAnimating = ref(false);
const { width, height } = useElementSize(zoomerContainer);

watch([ width, height ], () => {
  if (width.value && height.value) {
    reset(false);
  }
});

watch(() => imageMode, () => {
  if (!disabled) {
    return;
  }

  if ((mainImageX ?? null) === null) {
    return;
  }

  center({
    x: mainImageX!,
    y: mainImageY!
  }, true);
});

// NOTE: if we want prevent overdragging, we need to calculate minScale
// const minScale = computed(() => {
//   if (!disabled) {
//     return Math.max(width.value / paneSizeX, height.value / paneSizeY);
//   }
//   if (imageMode === 'contain') {
//     return Math.min(width.value / imageSizeX, height.value / imageSizeY);
//   }
//   return Math.max(width.value / imageSizeX, height.value / imageSizeY);
// });

const minScale = computed(() => {
  if (disabled) {
    return 1;
  }
  // use Math.max to cover, use Math.min to contain
  return Math.min(width.value / paneSizeX, height.value / paneSizeY);
});

const maxScale = computed(() => {
  if (disabled) {
    return 30;
  }
  if (imageMode === 'contain') {
    return Math.min(width.value / imageSizeX, height.value / imageSizeY);
  }
  return Math.max(width.value / imageSizeX, height.value / imageSizeY);
});

const state = reactive({
  scale: 1,
  originX: 0,
  originY: 0,
  translateX: 0,
  translateY: 0
});

const scale = computed(() => state.scale);
const originX = computed(() => state.originX);
const originY = computed(() => state.originY);
const translateX = computed(() => state.translateX);
const translateY = computed(() => state.translateY);

function onEvent(e: PinchScrollZoomEmitData, name: string) {
  // NOTE: prevent overdragging code. Must be moved into pinch-scroll-zoom because of debounce
  // if (!isAnimating.value && disabled && imageMode === 'cover') {
  //   const rect = zoomer.value.$el.getBoundingClientRect();
  //   const boxRect = zoomer.value.$el.querySelector('img.blur-0').getBoundingClientRect();

  //   if (boxRect.x > rect.x) {
  //     e.translateX -= (boxRect.x - rect.x);
  //   }
  //   if (boxRect.x + boxRect.width < rect.x + rect.width) {
  //     e.translateX += (rect.x + rect.width - boxRect.x - boxRect.width);
  //   }
  //   if (boxRect.y > rect.y) {
  //     e.translateY -= (boxRect.y - rect.y);
  //   }
  //   if (boxRect.y + boxRect.height < rect.y + rect.height) {
  //     e.translateY += (rect.y + rect.height - boxRect.y - boxRect.height);
  //   }
  // }

  Object.assign(state, {
    scale: e.scale,
    originX: e.originX,
    originY: e.originY,
    translateX: e.translateX,
    translateY: e.translateY
  });

  emit(name as keyof typeof emit, e);
}

async function setState(newState: any, animate = true) {
  return new Promise(resolve => {
    if (!animate) {
      Object.assign(state, newState);
      zoomer.value?.setData(newState);
      resolve(null);
      return;
    }

    isAnimating.value = true;

    const from = ref(Object.values(state));

    const animatedState = useTransition(from, {
      duration: 200,
      transition: TransitionPresets.linear,
      onFinished: async () => {
        pause?.();
        await setState(newState, false);
        isAnimating.value = false;
        resolve(null);
      }
    });

    const { pause } = useRafFn(() => {
      const val = animatedState.value;
      setState({
        scale: val[0],
        originX: val[1],
        originY: val[2],
        translateX: val[3],
        translateY: val[4]
      }, false);
    });

    from.value = Object.values(newState);
  });
}

function reset(animate = true) {
  // 400 to scroll a bit into pane to hide the top padding
  const x = mainImageX ?? 0; // parseInt(paneSizeX / 2, 10);
  const y = mainImageY ?? 400; // parseInt(paneSizeY / 2, 10);

  let _scale = maxScale.value * (isMobile.value ? 0.3 : 0.2);
  if (disabled) {
    if (imageMode === 'contain') {
      _scale = Math.min(width.value / imageSizeX, height.value / imageSizeY);
    } else {
      _scale = Math.max(width.value / imageSizeX, height.value / imageSizeY);
    }
  }

  const newState = {
    scale: _scale,
    originX: x,
    originY: y,
    translateX: (width.value / 2) - x,
    translateY: (height.value / 2) - y
  };

  setState(newState, animate);
}

function center({ x, y }: {x: number, y: number}, animate = true) {
  let _scale = maxScale.value;
  if (disabled) {
    if (imageMode === 'contain') {
      _scale = Math.min(width.value / imageSizeX, height.value / imageSizeY);
    } else {
      _scale = Math.max(width.value / imageSizeX, height.value / imageSizeY);
    }
  }

  setState({
    scale: _scale,
    originX: x,
    originY: y,
    translateX: (width.value / 2) - x,
    translateY: (height.value / 2) - y
  }, animate);
}
</script>
