<template>
  <div class="w-full h-full flex flex-col relative bg-blueprint">
    <div v-if="pageState === 'loading'" class="w-full h-full flex flex-col justify-center items-center text-white/30">
      <template v-if="timedOut">
        Loading image…
      </template>
    </div>

    <router-view
      v-else-if="pageState === 'loaded'"
      :key="imageId"
      :image-id="imageId"
      :image-size="imageSize"
      :image-sequence="imageSequence"
      :original-url="originalUrl"
      :original-filename="originalFilename"
      :placeholder-url="placeholderUrl"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, ShallowRef, shallowRef, watch } from 'vue';
import { cropSequence } from '../cropSequence';
import { clear as clearStateStorage } from '../stateByImageId';
import { open as showErrorPage } from '../errorPage';
import { API_PREFIX } from '../constants';
import { ImageSizeStructure } from '../paneConstants';

const { imageId } = defineProps<{
  imageId: string;
}>();

type PageState = 'loaded' | 'loading';
const pageState: ShallowRef<PageState> = shallowRef('loading');

const placeholderUrl: ShallowRef<string | null> = shallowRef(null);
const originalFilename: ShallowRef<string | null> = shallowRef(null);
const originalUrl: ShallowRef<string | null> = shallowRef(null);

const timedOut = shallowRef(false);
let timedOutTimeout = null;

const imageSequence: ShallowRef<number | null> = shallowRef(null);

const imageSize = ref<ImageSizeStructure>({
  width: null,
  height: null
});

async function loadImage() {
  timedOut.value = false;

  timedOutTimeout = setTimeout(() => timedOut.value = true, 8000);
  pageState.value = 'loading';

  let json = null;
  let status = null;

  try {
    const response = await fetch(API_PREFIX + '/image/info/' + imageId);
    status = response.status;
    json = await response.json();
  } catch (e) {
    // ignore
  }

  clearTimeout(timedOutTimeout);
  timedOutTimeout = null;
  timedOut.value = false;

  if (!json?.success) {
    if (status === 404) {
      showErrorPage("404", "Image not found");

    } else if (status === 403) {
      showErrorPage("403", "Access denied to this image");

    } else {
      showErrorPage("500", "Internal server error");
    }

    return;
  }

  clearStateStorage(imageId);

  originalUrl.value = json.originalUrl;
  placeholderUrl.value = json.placeholderUrl;
  originalFilename.value = json.filename;
  imageSize.value.width = json.width;
  imageSize.value.height = json.height;
  imageSequence.value = json.sequence;

  // preload placeholder
  (new Image()).src = placeholderUrl.value!;

  // we trust that the image exists actually, so this code
  // is left here for reference only
  // img.onerror = () => pageState.value = PAGE_STATE_FAILED;

  pageState.value = 'loaded';
}

watch(() => imageId, loadImage, { immediate: true });
watch(cropSequence, loadImage);
</script>
