<template>
  <modal-draggable :is-open="isOpen">
    <div class="space-y-4 pb-8" :class="isMobile ? '' : 'w-60'">
      <modal-header v-if="isMobile" is-bw icon="Settings" title="Adjustments" @close="emit('close')" />

      <div v-else class="flex items-center px-6 pt-3 mb-4">
        <h3 class="text-white mr-auto">Adjustments</h3>

        <button-close class="-right-3" @click="emit('close')" />
      </div>

      <slider-range
        key="exposure"
        v-model="exposure"
        class="px-6"
        :min="EXPOSURE_SLIDER_MIN"
        :max="EXPOSURE_SLIDER_MAX"
        :step="EXPOSURE_SLIDER_STEP"
        label="Exposure"
        @dblclick="setDefault('exposure')"
      />
      <slider-range
        key="contrast"
        v-model="contrast"
        class="px-6"
        :min="CONTRAST_SLIDER_MIN"
        :max="CONTRAST_SLIDER_MAX"
        :step="CONTRAST_SLIDER_STEP"
        label="Contrast"
        @dblclick="setDefault('contrast')"
      />
      <slider-range
        key="temperature"
        v-model="temperature"
        class="px-6"
        :min="TEMPERATURE_SLIDER_MIN"
        :max="TEMPERATURE_SLIDER_MAX"
        :step="TEMPERATURE_SLIDER_STEP"
        label="Temperature"
        @dblclick="setDefault('temperature')"
      />
      <slider-range
        key="tint"
        v-model="tint"
        class="px-6"
        :min="TINT_SLIDER_MIN"
        :max="TINT_SLIDER_MAX"
        :step="TINT_SLIDER_STEP"
        label="Tint"
        @dblclick="setDefault('tint')"
      />
      <slider-range
        key="colorBoost-slider"
        v-model="color_boost"
        class="px-6"
        :min="COLOR_BOOST_SLIDER_MIN"
        :max="COLOR_BOOST_SLIDER_MAX"
        :step="COLOR_BOOST_SLIDER_STEP"
        label="Color boost"
        @dblclick="setDefault('color_boost')"
      />

      <div class="text-center mt-4">
        <button type="button" class="btn-xs text-xs" :disabled="!isResetButtonEnabled" @click="resetAll()">Reset</button>
      </div>
    </div>
  </modal-draggable>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ModalDraggable from './ModalDraggable.vue';
import SliderRange from './SliderRange.vue';
import ButtonClose from './ButtonClose.vue';
import ModalHeader from './ModalHeader.vue';
import { isMobile } from '../breakpoints';
import { presets } from '../presets';
import {
  COLOR_BOOST_SLIDER_MIN,
  COLOR_BOOST_SLIDER_MAX,
  COLOR_BOOST_SLIDER_STEP,
  EXPOSURE_SLIDER_MIN,
  EXPOSURE_SLIDER_MAX,
  EXPOSURE_SLIDER_STEP,
  CONTRAST_SLIDER_MIN,
  CONTRAST_SLIDER_MAX,
  CONTRAST_SLIDER_STEP,
  TEMPERATURE_SLIDER_MIN,
  TEMPERATURE_SLIDER_MAX,
  TEMPERATURE_SLIDER_STEP,
  TINT_SLIDER_MIN,
  TINT_SLIDER_MAX,
  TINT_SLIDER_STEP
} from '../paneConstants';

export type ModalSettingsStateUpdate = {
  contrast?: number;
  exposure?: number;
  temperature?: number;
  tint?: number;
  color_boost?: number;
};

type PropertyNames = keyof ModalSettingsStateUpdate;

const { state } = defineProps<{
  isOpen: boolean;
  state: any; // FIXME any
}>();

const emit = defineEmits<{
  close: [void]
  update: [ModalSettingsStateUpdate]
}>();

const color_boost = computed({
  get() {
    return state.color_boost;
  },
  set(value) {
    emit('update', { color_boost: value });
  }
});

const exposure = computed({
  get() {
    return state.exposure;
  },
  set(value) {
    emit('update', { exposure: value });
  }
});

const contrast = computed({
  get() {
    return state.contrast;
  },
  set(value) {
    emit('update', { contrast: value });
  }
});

const temperature = computed({
  get() {
    return state.temperature;
  },
  set(value) {
    emit('update', { temperature: value });
  }
});

const tint = computed({
  get() {
    return state.tint;
  },
  set(value) {
    emit('update', { tint: value });
  }
});

const isResetButtonEnabled = computed(() => (
  (state.exposure ?? 0) !== 0
  || (state.contrast ?? 0) !== 0
  || (state.tint ?? 0) !== 0
  || (state.temperature ?? 0) !== 0
  || (state.color_boost ?? 0) !== 0
));

function setDefault(property: PropertyNames) {
  const defaults = presets.value[state.presetId];
  if (!defaults) {
    return;
  }

  const value = defaults[property];
  emit('update', { [property]: value });
}

function resetAll() {
  const defaults = presets.value[state.presetId];
  if (!defaults) {
    return;
  }

  emit('update', {
    contrast: defaults.contrast,
    exposure: defaults.exposure,
    temperature: defaults.temperature,
    tint: defaults.tint,
    color_boost: defaults.color_boost
  });
}
</script>
