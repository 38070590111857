import {
  PANES,
  EXPOSURE_STEP_UP,
  EXPOSURE_STEP_DOWN,
  CONTRAST_STEP_DOWN,
  CONTRAST_STEP_UP,
  STEP_TEMPERATURE,
  STEP_TINT,
  PaneMode
} from './paneConstants.js';
import { open as showErrorPage } from './errorPage.js';
import { presets } from './presets.js';

export type TranslatePaneObjectToApiRequestStructureOutput = {
  mode: PaneMode;
  presetId?: number;
  colorId: number;
  lightId: number;
  tint: number;
  temperature: number;
  contrast: number;
  exposure: number;
  color_boost: number;
  bloom: number;
  halation: number;
  grain: number;
  vignette_exposure: number;
  vignette_feather: number;
  vignette_size: number;
  sequence: number;
}

export type PaneObject = {
  imageId: string;
  mode: PaneMode;
  presetId?: number;
  tint: number;
  temperature: number;
  contrast: number;
  exposure: number;
  color_boost: number;
  sequence: number;
  is_bloom_enabled: boolean;
  is_halation_enabled: boolean;
  is_grain_enabled: boolean;
  bloom: number;
  halation: number;
  grain: number;
  is_vignette_enabled: boolean;
  vignette_exposure: number;
  vignette_feather: number;
  vignette_size: number;
}

export type DehancerImageState = {
  preset: string;
  contrast: number;
  exposure: number;
  temperature: number;
  tint: number;
  color_boost?: number;
  bloom?: number;
  halation?: number;
  grain?: number;
  sequence?: number;
  vignette_exposure?: number;
  vignette_feather?: number;
  vignette_size?: number;
};

export type RenderImageSize = 'large' | 'small';

export type TranslatePaneObjectToMultipleImagesApiRequestStructureOutput = {
  imageId: string;
  size: RenderImageSize;
  states: DehancerImageState[];
};

export function generateDehancerStatesFromPaneObject(paneObject: PaneObject): DehancerImageState[] | undefined {
  const {
    mode,
    presetId,
    contrast,
    exposure,
    temperature,
    tint,
    color_boost,
    sequence
  } = translatePaneObjectToApiRequestStructure(paneObject);

  // Bloom, halation and vignette will be reset once the preset has been selected,
  // so we better not even show it on presets pane. Grain will be reset, but we show the grain on the grid.

  switch (mode) {
    case 'preset': {
      const states: DehancerImageState[] = [];
      for (let i = 0; i < PANES[mode].itemsTotal!; i++) {
        const preset = presets.value[i];

        if (!preset) {
          showErrorPage("Oops", "No preset in global");
          return undefined;
        }

        states.push({
          preset: preset.preset,
          grain: preset.grain, // show default grain
          contrast,
          exposure,
          temperature,
          tint,
          color_boost,
          sequence
        });
      }

      return states;
    }

    case 'light': {
      const preset = presets.value[presetId!].preset;

      const states = [];
      for (let i = 0; i < PANES[mode].itemsTotal!; i++) {
        const { contrast: _contrast, exposure: _exposure } = translateFromLightId(i);

        states.push({
          preset,
          contrast: _contrast,
          exposure: _exposure,
          temperature,
          tint,
          color_boost,
          sequence
        });
      }

      return states;
    }

    case 'color': {
      const preset = presets.value[presetId!].preset;

      const states = [];
      for (let i = 0; i < PANES[mode].itemsTotal!; i++) {
        const { tint: _tint, temperature: _temperature } = translateFromColorId(i);

        states.push({
          preset,
          contrast,
          exposure,
          temperature: _temperature,
          tint: _tint,
          color_boost,
          sequence
        });
      }

      return states;
    }

    case 'settings': {
      const preset = presets.value[presetId!].preset;

      const states = [
        {
          preset,
          contrast,
          exposure,
          temperature,
          tint,
          color_boost,
          sequence
        }
      ];

      return states;
    }

    default:
      return undefined;
  }
}

export function translatePaneObjectToSingleImageApiRequestStructure(paneObject: PaneObject): TranslatePaneObjectToMultipleImagesApiRequestStructureOutput {
  const { imageId } = paneObject;
  const {
    presetId,
    contrast,
    exposure,
    temperature,
    tint,
    color_boost,
    bloom,
    halation,
    grain,
    vignette_exposure,
    vignette_feather,
    vignette_size,
    sequence
  } = translatePaneObjectToApiRequestStructure(paneObject);

  const preset = presets.value[presetId!].preset;

  const state: DehancerImageState = {
    preset,
    contrast,
    exposure,
    temperature,
    tint,
    color_boost,
    bloom,
    halation,
    grain,
    vignette_exposure,
    vignette_feather,
    vignette_size,
    sequence
  };

  return {
    imageId,
    size: 'large',
    states: [ state ]
  };
}

function translatePaneObjectToApiRequestStructure(paneObject: PaneObject): TranslatePaneObjectToApiRequestStructureOutput {
  const { mode, presetId, tint, temperature, contrast, exposure, color_boost, sequence } = paneObject;

  const colorId = transformToColorId(tint, temperature);
  const lightId = transformToLightId(contrast, exposure);

  const bloom = paneObject.is_bloom_enabled ? paneObject.bloom : 0;
  const halation = paneObject.is_halation_enabled ? paneObject.halation : 0;
  const grain = paneObject.is_grain_enabled ? paneObject.grain : 0;

  const vignette_exposure = paneObject.is_vignette_enabled ? paneObject.vignette_exposure : 0;
  const vignette_feather = paneObject.vignette_feather;
  const vignette_size = paneObject.vignette_size;

  return {
    mode,
    presetId,
    colorId,
    lightId,
    tint,
    temperature,
    contrast,
    exposure,
    color_boost,
    bloom,
    halation,
    grain,
    vignette_exposure,
    vignette_feather,
    vignette_size,
    sequence
  };
}

export function translateFromColorId(colorId: number): { tint: number, temperature: number } {
  const { x, y } = idToCoordinates(colorId, 'color');

  const temperature = x * STEP_TEMPERATURE;
  const tint = -y * STEP_TINT;

  return { tint, temperature };
}

export function transformToColorId(tint: number, temperature: number): number {
  const { itemsPerRow, itemsTotal } = PANES['color'];

  const temperatureMultiplier = (itemsPerRow - 1) / 2;
  const tintMultiplier = (Math.ceil(itemsTotal! / itemsPerRow) - 1) / 2;

  const temperatureClamped = temperature < 0 ? Math.max(temperature, -STEP_TEMPERATURE * temperatureMultiplier) : Math.min(temperature, STEP_TEMPERATURE * temperatureMultiplier);
  const tintClamped = tint < 0 ? Math.max(tint, -STEP_TINT * tintMultiplier) : Math.min(tint, STEP_TINT * tintMultiplier);

  const x = temperatureClamped / STEP_TEMPERATURE;
  const y = -tintClamped / STEP_TINT;

  const id = coordinatesToId(x, y, 'color');

  if (id > itemsTotal! - 1) {
    console.log('transformToColorId BUG', { id, x, y, temperature, tint, temperatureClamped, tintClamped });
    return itemsTotal! - 1;
  }

  return id;
}

export function translateFromLightId(lightId: number): { contrast: number, exposure: number } {
  const { x, y } = idToCoordinates(lightId, 'light');

  const contrast = x < 0 ? (x * CONTRAST_STEP_DOWN) : (x * CONTRAST_STEP_UP);
  const exposure = y < 0 ? (-y * EXPOSURE_STEP_UP) : (-y * EXPOSURE_STEP_DOWN);

  return { contrast, exposure };
}

export function transformToLightId(contrast: number, exposure: number): number {
  const { itemsPerRow, itemsTotal } = PANES['light'];

  const contrastMultiplier = (itemsPerRow - 1) / 2;
  const exposureMultiplier = (Math.ceil(itemsTotal! / itemsPerRow) - 1) / 2;

  const contrastClamped = contrast < 0 ? Math.max(contrast, -CONTRAST_STEP_DOWN * contrastMultiplier) : Math.min(contrast, CONTRAST_STEP_UP * contrastMultiplier);
  const exposureClamped = exposure < 0 ? Math.max(exposure, -EXPOSURE_STEP_DOWN * exposureMultiplier) : Math.min(exposure, EXPOSURE_STEP_UP * exposureMultiplier);

  const x = contrastClamped < 0 ? (contrastClamped / CONTRAST_STEP_DOWN) : (contrastClamped / CONTRAST_STEP_UP);
  const y = exposureClamped < 0 ? (-exposureClamped / EXPOSURE_STEP_DOWN) : (-exposureClamped / EXPOSURE_STEP_UP);

  const id = coordinatesToId(x, y, 'light');

  if (id > itemsTotal! - 1) {
    console.log('transformToLightId BUG', { id, x, y, contrast, exposure, contrastClamped, exposureClamped });
    return itemsTotal! - 1;
  }

  return id;
}

// NOTE: sometimes we got non-integer coordinates (when per-row or per-column count is even)
function idToCoordinates(id: number, mode: PaneMode): { x: number, y: number } {
  const { itemsPerRow, itemsTotal } = PANES[mode];
  const itemsPerColumn = Math.ceil(itemsTotal! / itemsPerRow);
  const x = id % itemsPerRow - (itemsPerRow - 1) / 2;
  const y = Math.floor(id / itemsPerRow) - (itemsPerColumn - 1) / 2;

  return { x, y };
}

function coordinatesToId(x: number, y: number, mode: PaneMode): number {
  const { itemsPerRow, itemsTotal } = PANES[mode];
  const itemsPerColumn = Math.ceil(itemsTotal! / itemsPerRow);
  const idX = Math.round(x + (itemsPerRow - 1) / 2);
  const idY = Math.round(y + (itemsPerColumn - 1) / 2);

  return idY * itemsPerRow + idX;
}

// returns distance by id from given point
export function getDistances(paneObject: PaneObject): { [key: number]: number } {
  const mode = paneObject.mode;

  let fromId = null;
  if (mode === 'preset') {
    fromId = paneObject.presetId;

  } else if (mode === 'light') {
    fromId = transformToLightId(paneObject.contrast, paneObject.exposure);

  } else if (mode === 'color') {
    fromId = transformToColorId(paneObject.tint, paneObject.temperature);
  }

  const { itemsTotal } = PANES[mode];

  // use pane center if no fromId given
  const fromCoordinates = fromId ? idToCoordinates(fromId, mode) : { x: 0, y: 0 };

  const distance = (id: number) => {
    const { x, y } = idToCoordinates(id, mode);
    return Math.max(Math.abs(fromCoordinates.x - x), Math.abs(fromCoordinates.y - y));
  };

  const distances: Record<number, number> = {};
  for (let i = 0; i < itemsTotal!; i++) {
    distances[i] = distance(i);
  }
  return distances;
}
