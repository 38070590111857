<template>
  <authorize-page v-if="!isWorkstationAuthorized" />

  <signin-page v-else-if="isLoginPageVisible" />

  <failed-status v-else-if="isErrorPageOpen" />

  <div v-else class="w-full h-full overflow-auto relative">
    <router-view />
    <lock-screen />
  </div>
</template>

<script setup lang="ts">
import AuthorizePage from './pages/AuthorizePage.vue';
import SigninPage from './pages/SigninPage.vue';
import FailedStatus from './components/FailedStatus.vue';
import LockScreen from './components/LockScreen.vue';
import { isOpen as isErrorPageOpen } from './errorPage';
import { isWorkstationAuthorized, isLoginPageVisible } from './auth';
import { fetch as fetchWhatsNew } from './whatsnew';

fetchWhatsNew(); // fire-and-forget, it will open the dialog automatically
</script>
