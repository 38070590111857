import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed } from 'vue';

const breakpoints = useBreakpoints(breakpointsTailwind);

export const isMobile = breakpoints.smaller('lg');

export const isStandalone = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;

export const itemsPerRow = computed(() => {
  if (breakpoints.smallerOrEqual('sm').value) {
    return 3;
  }

  if (breakpoints.smallerOrEqual('md').value) {
    return 4;
  }

  if (breakpoints.smallerOrEqual('lg').value) {
    return 4;
  }

  if (breakpoints.smallerOrEqual('xl').value) {
    return 6;
  }

  return 8;
});
