<template>
  <vue-upload-component
    key="1"
    ref="uploader"
    v-model="files"
    :drop="files.length ? null : dropTarget"
    :drop-directory="false"
    :custom-action="customAction"
    :chunk="chunk"
    class="!hidden"
    accept="image/jpeg,image/x-adobe-dng,image/heif,image/heic,image/avif,image/webp,image/tiff,image/png"
    @input-filter="onInputFilter"
    @input-file="onInputFile"
  />

  <div
    class="mx-auto"
    :class="{
      'cursor-pointer': state === 'idle',
      'aspect-square max-w-md': !wide,
    }"
    @click="errorMessage ? errorMessage = null : openFileDialog()"
  >
    <div
      class="flex justify-center items-center md:px-10 py-10 bg-[#2e2e2e] w-full h-full rounded-2xl"
      :class="{
        'dashed-border': state === 'idle',
        'py-20': wide
      }"
    >
      <div v-if="state === 'idle' && errorMessage" class="text-center text-danger">
        <icon-death class="mx-auto h-12 w-12" aria-hidden="true" />
        <div class="mt-4">
          <p>{{ errorMessage }}</p>
        </div>
        <div class="mt-4">
          <button class="link">Okay</button>
        </div>
      </div>
      <div v-else-if="state === 'idle'" class="text-center text-info">
        <div class="flex items-center justify-center space-x-2.5">
          <icon-image :class="isTrial ? 'size-16' : 'size-12'" aria-hidden="true" />
          <div v-if="isTrial" class="text-2xl font-black leading-none w-32 text-left">TRY NOW FOR FREE!</div>
        </div>
        <div class="mt-10">
          <p>Drag photo or click to upload</p>
        </div>
        <p class="">JPEG, HEIC, TIFF or DNG</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, shallowRef, nextTick, watch, onBeforeUnmount, Ref, ShallowRef } from 'vue';
import IconImage from './icons/IconImage.vue';
import IconDeath from './icons/IconDeath.vue';
import VueUploadComponent, { VueUploadItem } from 'vue-upload-component';
import { API_PREFIX } from '../constants';
// @ts-ignore chunkUploadHandler
import ChunkUploadHandler from '../chunkUploadHandler.js';
import { post } from '../post';
import { open as openLockScreen, close as closeLockScreen, progress as lockScreenProgress, message as lockScreenMessage } from '../lockScreen';
import { open as showErrorPage } from '../errorPage';

defineProps<{
  isTrial?: boolean;
  wide?: boolean;
  dropTarget: HTMLElement | null;
}>();

const emit = defineEmits<{
  uploaded: [string]
}>();

const uploader: Ref<typeof VueUploadComponent | null> = ref(null);
const files: Ref<VueUploadItem[]> = ref([]);

type States = 'idle' | 'preparing' | 'uploading' | 'uploaded';
const state: ShallowRef<States> = shallowRef('idle');

const chunk: any = ref(null);

const errorMessage: ShallowRef<string | null> = shallowRef(null);

const isUploading = computed(() => uploader.value?.active || state.value !== 'idle');
const isDragging = computed(() => uploader.value?.dropActive ?? false);

function openFileDialog() {
  if (state.value !== 'idle') {
    return;
  }
  uploader.value!.$refs.input.click();
}

defineExpose({ isUploading, isDragging, openFileDialog });

const ALLOWED_FILE_TYPES: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/tiff': 'tiff',
  'image/heif': 'heif',
  'image/heic': 'heic',
  'image/avif': 'avif',
  'image/webp': 'webp',
  'image/png': 'png',
  'image/x-adobe-dng': 'dng'
};

const MIME_TYPES_BY_EXTENSION: Record<string, string> = {
  jpg: 'image/jpeg',
  tiff: 'image/tiff',
  heif: 'image/heif',
  heic: 'image/heic',
  avif: 'image/avif',
  webp: 'image/webp',
  png: 'image/png',
  dng: 'image/x-adobe-dng'
};

const MAX_SIZE_MB_DEFAULT = 100;

const MAX_SIZE_MB: Record<string, number> = {
  // larger than the size of a 100MP image from Fuji GFX 100 II but upsized to a square, 8bit color no transparency, no compression
  'image/tiff': 400,

  // iPhone raw max size in DNG format
  'image/x-adobe-dng': 100
};

function getExtensionFromFilename(filename: string): string {
  const lcFilename = filename.toLowerCase();
  const s = lcFilename.match(/\.([^.]+)$/);
  if (!s) {
    return '';
  }

  const extension = s[1];

  if (extension === 'jpeg') {
    return 'jpg';
  }

  if (extension === 'tif') {
    return 'tiff';
  }

  return extension;
}

function onInputFilter(newFile: any, oldFile: any, prevent: Function) {
  if (!newFile || oldFile) {
    return;
  }

  const mimetype = MIME_TYPES_BY_EXTENSION[getExtensionFromFilename(newFile.name)]; // because newFile.type is empty sometimes in Chrome

  if (!ALLOWED_FILE_TYPES[mimetype]) {
    errorMessage.value = 'Only certain image files are allowed';
    prevent();
    return;
  }

  const maxSizeMb = MAX_SIZE_MB[mimetype] ?? MAX_SIZE_MB_DEFAULT;

  if (newFile.size > maxSizeMb * 1024 * 1024) {
    errorMessage.value = `Max file size for ${mimetype} is ${maxSizeMb}Mb`;
    prevent();
    return; // eslint-disable-line no-useless-return
  }

  if (newFile.name.length > 200) {
    errorMessage.value = `Filename too long`;
    prevent();
    return; // eslint-disable-line no-useless-return
  }
}

watch(
  () => files.value?.[0]?.progress,
  (currentProgress?: string) => {
    if (currentProgress === undefined || currentProgress === null) {
      return;
    }

    const num = parseFloat(currentProgress);
    if (Number.isNaN(num)) {
      return;
    }

    lockScreenProgress.value = num * 0.8;
  }
);

function onInputFile(newFile: any, oldFile: any) {
  if (uploader.value?.active || !newFile?.file || newFile.id === oldFile?.id) {
    return;
  }

  uploader.value!.active = true;
}

let updateProcessingInterval: number | null = null;

onBeforeUnmount(() => {
  if (updateProcessingInterval !== null) {
    clearInterval(updateProcessingInterval);
  }
});

async function customAction(file: VueUploadItem, self: typeof VueUploadComponent): Promise<VueUploadItem> {
  errorMessage.value = null;

  if (state.value !== 'idle') {
    return file;
  }

  state.value = 'preparing';

  const mimetype = MIME_TYPES_BY_EXTENSION[getExtensionFromFilename(file.name!)]; // because newFile.type is empty sometimes in Chrome

  openLockScreen('Preparing upload', 0);

  const { json: jsonGenerateSignedUrl, status: statusGenerateSignedUrl } = await post({
    url: API_PREFIX + '/upload/prepare',
    data: {
      mimetype,
      size: file.size
    }
  });

  if (!jsonGenerateSignedUrl?.success) {
    state.value = 'idle';

    if (statusGenerateSignedUrl === 400) {
      closeLockScreen();
      showErrorPage("400", "Invalid file type", "Server doesn't support this file type. How could this happen?");

    } else {
      closeLockScreen();
      showErrorPage("Oops", "Upload error", jsonGenerateSignedUrl?.error || "Unknown error");
    }

    return file;
  }

  // this is a failsafe fix, should never happen; but when this happens, frontend starts and infinite cycle,
  // so we have to protect the user from this
  if (jsonGenerateSignedUrl.isMultipart && jsonGenerateSignedUrl.urls.length === 0) {
    state.value = 'idle';
    closeLockScreen();
    showErrorPage("500", "Internal server error");
    return file;
  }

  state.value = 'uploading';

  lockScreenMessage.value = 'Uploading ' + file.name;

  if (jsonGenerateSignedUrl.isMultipart) {
    chunk.value = {
      handler: ChunkUploadHandler,
      chunkSize: jsonGenerateSignedUrl.chunkSize,
      urls: jsonGenerateSignedUrl.urls,
      maxActive: 5,
      maxRetries: 5
    };

    await nextTick();

    try {
      await self.uploadChunk(file);

    } catch (e) {
      state.value = 'idle';
      console.log("FAILED");
      console.log(e);
      closeLockScreen();
      showErrorPage("Oops", "Upload error", "Error (4)");
      return file;
    }

  } else {
    try {
      await self.uploadPut({ ...file, putAction: jsonGenerateSignedUrl.url });

    } catch (e) {
      state.value = 'idle';
      console.log("FAILED");
      console.log(e);
      closeLockScreen();
      showErrorPage("Oops", "Upload error", "Error (3)");
      return file;
    }
  }

  state.value = 'uploaded';

  lockScreenMessage.value = 'Processing ' + file.name;

  updateProcessingInterval = setInterval(() => {
    if (lockScreenProgress.value! >= 100) {
      clearInterval(updateProcessingInterval!);
      updateProcessingInterval = null;
      return;
    }
    lockScreenProgress.value! += 0.8;
  }, 300);

  // Now we have to employ promises and not await because we must let the screen render into 'uploaded' state.
  // Which somehow does not happen if we await here.

  const imageId: string = jsonGenerateSignedUrl.imageId;

  const { json: jsonUploaded, status: statusUploaded } = await post({
    url: API_PREFIX + '/upload/finish',
    data: {
      imageId,
      uploadId: jsonGenerateSignedUrl.uploadId,
      etags: file.etags,
      filename: file.name
    }
  });

  files.value = [];

  closeLockScreen();

  if (updateProcessingInterval !== null) {
    clearInterval(updateProcessingInterval);
    updateProcessingInterval = null;
  }

  if (!jsonUploaded?.success) {
    state.value = 'idle';

    if (statusUploaded === 403) {
      showErrorPage("403", "Image already exists");

    } else if (statusUploaded === 500) {
      showErrorPage("500", "Internal server error");

    } else if (statusUploaded === 999) {
      showErrorPage("Timeout", "We have spent too much time and failed.");

    } else {
      showErrorPage("Oops", "Upload error", jsonUploaded?.error || "Unknown error");
    }

    return file;
  }

  emit('uploaded', imageId);

  return file;
}
</script>
