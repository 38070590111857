export function formatDateLocale(time: any) {
  return new Date(time).toLocaleString();
}

export function formatDateUTC(time: any) {
  return new Date(time)
    .toISOString()
    .replaceAll('T', ' ')
    .replaceAll('Z', '')
    .replace(/\.\d\d\d$/, '');
}
