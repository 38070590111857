<template>
  <div class="w-full min-h-full flex flex-col justify-center items-center bg-blueprint">
    <div class="shrink-0 w-full bg-main text-white/60 shadow-lg z-20">
      <div class="inline-flex items-center mr-auto px-1 xs:pr-6 ml-4 md:ml-10" :class="isMobile ? 'h-12' : 'h-16 md:h-[76px]'">
        <the-logo-text class="h-8 md:h-10 cursor-pointer" @click.prevent="closeLogin" />
      </div>
    </div>
    <div class="grow flex flex-col justify-start items-center pt-2 tall:pt-[10%]">
      <steps-block class="mb-20" :steps="steps" :current-step="currentStep" />

      <div class="pt-4 pb-8 space-y-4 tall:pt-8 tall:pb-16 tall:space-y-8 xs:bg-main rounded-2xl text-center px-8 xs:tall:px-14 min-w-[min(100vw,380px)]">
        <signup-block class="w-full" @steps-changed="stepsChanged" @current-step-changed="currentStepChanged" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { purchaseAfterLoginData, closeLogin } from '../auth';
import { isMobile } from '../breakpoints';
import TheLogoText from '../components/icons/TheLogoText.vue';
import SignupBlock from '../components/SignupBlock.vue';
import StepsBlock from '../components/StepsBlock.vue';
import { ref, shallowRef } from 'vue';

const steps = ref([
  'Email',
  'Password',
  'Auth'
]);

if (purchaseAfterLoginData.value) {
  steps.value.push('Purchase');
}

const currentStep = shallowRef(0);

function currentStepChanged(newCurrentStep: number) {
  currentStep.value = newCurrentStep;
}

function stepsChanged(newSteps: string[]) {
  steps.value = newSteps;
}
</script>
