import { Reactive, reactive } from 'vue';
import { itemsPerRow } from './breakpoints.js';

export type PinchScrollZoomEmitData = {
  x: number;
  y: number;
  scale: number;
  originX: number;
  originY: number;
  translateX: number;
  translateY: number;
};

export type RenderFormats = 'jpeg' | 'tiff' | 'web';

export type ScrollPaneFitMode = 'contain' | 'cover';

export type ImageSizeStructure = {
  width: number | null;
  height: number | null;
};

export type PaneMode = 'preset' | 'light' | 'color' | 'settings';

export type PaneValue = {
  mainPropertyKeyNames: string[];
  itemsTotal: number | null;
  itemsPerRow: number;
};

export const PANES: Reactive<Record<PaneMode, PaneValue>> = reactive({
  preset: {
    mainPropertyKeyNames: [
      'presetId',
      // must not be included in key for caching
      'bloom',
      'halation',
      'grain',
      'is_bloom_enabled',
      'is_grain_enabled',
      'is_halation_enabled',
      'is_vignette_enabled',
      'vignette_exposure',
      'vignette_feather',
      'vignette_size',
      'sequence'
    ],
    itemsTotal: null,
    itemsPerRow: itemsPerRow.value
  },
  light: {
    mainPropertyKeyNames: [ 'contrast', 'exposure' ],
    itemsTotal: 36,
    itemsPerRow: 6
  },
  color: {
    mainPropertyKeyNames: [ 'temperature', 'tint' ],
    itemsTotal: 25,
    itemsPerRow: 5
  },
  settings: {
    mainPropertyKeyNames: [],
    itemsTotal: 1,
    itemsPerRow: 1
  }
});

export const EXPOSURE_STEP_UP = 0.8;
export const EXPOSURE_STEP_DOWN = 0.6;

export const CONTRAST_STEP_UP = 20;
export const CONTRAST_STEP_DOWN = 10;

export const STEP_TEMPERATURE = 20;
export const STEP_TINT = 20;
export const STEP_COLOR_BOOST = 20;

export const EXPOSURE_SLIDER_MIN = -2;
export const EXPOSURE_SLIDER_MAX = 2;
export const EXPOSURE_SLIDER_STEP = 0.2;

export const CONTRAST_SLIDER_MIN = -40;
export const CONTRAST_SLIDER_MAX = 40;
export const CONTRAST_SLIDER_STEP = 2;

export const TEMPERATURE_SLIDER_MIN = -90;
export const TEMPERATURE_SLIDER_MAX = 90;
export const TEMPERATURE_SLIDER_STEP = 2;

export const TINT_SLIDER_MIN = -90;
export const TINT_SLIDER_MAX = 90;
export const TINT_SLIDER_STEP = 2;

export const COLOR_BOOST_SLIDER_MIN = -90;
export const COLOR_BOOST_SLIDER_MAX = 90;
export const COLOR_BOOST_SLIDER_STEP = 2;

export const GRAIN_SLIDER_MIN = 0;
export const GRAIN_SLIDER_MAX = 100;
export const GRAIN_SLIDER_STEP = 2;

export const BLOOM_SLIDER_MIN = 0;
export const BLOOM_SLIDER_MAX = 100;
export const BLOOM_SLIDER_STEP = 2;

export const HALATION_SLIDER_MIN = 0;
export const HALATION_SLIDER_MAX = 100;
export const HALATION_SLIDER_STEP = 2;

export const VIGNETTE_EXPOSURE_SLIDER_MIN = -2;
export const VIGNETTE_EXPOSURE_SLIDER_MAX = 2;
export const VIGNETTE_EXPOSURE_SLIDER_STEP = 0.2;

export const VIGNETTE_SIZE_SLIDER_MIN = 5;
export const VIGNETTE_SIZE_SLIDER_MAX = 100;
export const VIGNETTE_SIZE_SLIDER_STEP = 2;

export const VIGNETTE_FEATHER_SLIDER_MIN = 4;
export const VIGNETTE_FEATHER_SLIDER_MAX = 40;
export const VIGNETTE_FEATHER_SLIDER_STEP = 2;
