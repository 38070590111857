<template>
  <div class="w-full mb-4" @pointerdown.stop @pointermove.stop>
    <label class="block mb-1 md:-mb-2 text-xs text-neutral-400">{{ label }}</label>
    <input
      v-model.number="rangeValue"
      type="range"
      class="w-full h-3 md:h-0.5 bg-neutral-400 rounded-lg cursor-pointer appearance-none
        [&::-webkit-slider-thumb]:w-8 md:[&::-webkit-slider-thumb]:w-3
        [&::-webkit-slider-thumb]:h-8 md:[&::-webkit-slider-thumb]:h-3
        [&::-webkit-slider-thumb]:appearance-none
        [&::-webkit-slider-thumb]:bg-primary-600
        [&::-webkit-slider-thumb]:rounded-full
        [&::-webkit-slider-thumb]:before:absolute
        [&::-webkit-slider-thumb]:before:-inset-2.5

        [&::-moz-range-thumb]:w-8 md:[&::-moz-range-thumb]:w-3
        [&::-moz-range-thumb]:h-8 md:[&::-moz-range-thumb]:h-3
        [&::-moz-range-thumb]:appearance-none
        [&::-moz-range-thumb]:bg-primary-600
        [&::-moz-range-thumb]:rounded-full

        [&::-ms-thumb]:w-8 md:[&::-ms-thumb]:w-3
        [&::-ms-thumb]:h-8 md:[&::-ms-thumb]:h-3
        [&::-ms-thumb]:appearance-none
        [&::-ms-thumb]:bg-primary-600
        [&::-ms-thumb]:rounded-full

        [&:disabled::-webkit-slider-thumb]:bg-neutral-600
        [&:disabled::-moz-range-thumb]:bg-neutral-600
        [&:disabled::-ms-thumb]:bg-neutral-600
      "
      :class="{ 'bg-neutral-600': disabled }"
      :min="min"
      :max="max"
      :step="step"
      :disabled="disabled"
    >
  </div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  label?: string;
  modelValue?: number;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
}>();

const emit = defineEmits<{
  'update:modelValue': [number]
}>();

const rangeValue = useVModel(props, 'modelValue', emit);
</script>
