<script>
import bem from 'easy-bem';
import debounce from 'debounce';
import RectangleStencil from './RectangleStencil.vue';
import CropperBackground from './CropperBackground.vue';
import {
  fillBoundaries,
  isLoadedImage
} from './core';
import { ManipulateImageEvent } from './core/events';
import { isEqual, limitSizeRestrictions } from './core/service';
import {
  getStyleTransforms
} from './core/image';
import { DEFAULT_COORDINATES } from './core/constants';
import * as algorithms from './core/algorithms';

const cn = bem('vue-advanced-cropper');

export default {
  components: {
    CropperBackground,
    RectangleStencil
  },
  props: {
    src: {
      type: String,
      default: null
    },
    aspectRatio: {
      type: [ Number ],
      default: null
    },
    debounce: {
      type: [ Boolean, Number ],
      default: 500
    },
    transitions: {
      type: Boolean,
      default: true
    },
    transitionTime: {
      type: Number,
      default: 150
    },
    margin: {
      type: Number,
      default: 0
    }
  },
  emits: [ 'change', 'error', 'ready' ],
  data() {
    return {
      transitionsActive: false,
      imageLoaded: false,
      imageAttributes: {
        width: null,
        height: null,
        src: null
      },
      defaultImageTransforms: {
        rotate: 0,
        flip: {
          horizontal: false,
          vertical: false
        }
      },
      appliedImageTransforms: {
        rotate: 0,
        flip: {
          horizontal: false,
          vertical: false
        }
      },
      boundaries: {
        width: 0,
        height: 0
      },
      visibleArea: null,
      coordinates: {
        ...DEFAULT_COORDINATES
      }
    };
  },
  computed: {
    image() {
      return {
        src: this.imageAttributes.src,
        width: this.imageAttributes.width,
        height: this.imageAttributes.height,
        transforms: this.imageTransforms
      };
    },
    imageTransforms() {
      return {
        rotate: this.appliedImageTransforms.rotate,
        flip: {
          horizontal: this.appliedImageTransforms.flip.horizontal,
          vertical: this.appliedImageTransforms.flip.vertical
        },
        translateX: this.visibleArea ? this.visibleArea.left / this.coefficient : 0,
        translateY: this.visibleArea ? this.visibleArea.top / this.coefficient : 0,
        scaleX: 1 / this.coefficient,
        scaleY: 1 / this.coefficient
      };
    },
    imageSize() {
      return {
        width: this.imageAttributes.width,
        height: this.imageAttributes.height
      };
    },
    initialized() {
      return Boolean(this.visibleArea && this.imageLoaded);
    },
    settings() {
      const resizeImage = {
        touch: true,
        wheel: {
          ratio: 0.01
        },
        adjustStencil: false
      };

      const moveImage = {
        touch: true,
        mouse: true
      };

      return {
        moveImage,
        resizeImage
      };
    },
    coefficient() {
      return this.visibleArea ? this.visibleArea.width / this.boundaries.width : 0;
    },
    transitionsOptions() {
      return {
        enabled: this.transitionsActive,
        timingFunction: 'ease-in-out',
        time: 350
      };
    },
    sizeRestrictions() {
      if (this.boundaries.width && this.boundaries.height && this.imageSize.width && this.imageSize.height) {
        let sizeRestrictions = algorithms.pixelsRestrictions({
          imageSize: this.imageSize,
          minWidth: 0,
          minHeight: 0,
          maxWidth: Infinity,
          maxHeight: Infinity
        });

        sizeRestrictions = algorithms.refineSizeRestrictions({
          sizeRestrictions,
          areaRestrictions: {},
          imageSize: this.imageSize,
          boundaries: this.boundaries,
          visibleArea: this.visibleArea
        });

        return sizeRestrictions;
      }
      return {
        minWidth: 0,
        minHeight: 0,
        maxWidth: 0,
        maxHeight: 0
      };

    },
    // Styling
    classes() {
      return {
        cropper: cn(),
        image: cn('image'),
        stencil: cn('stencil'),
        boundaries: cn('boundaries'),
        stretcher: cn('stretcher'),
        background: cn('background'),
        foreground: cn('foreground'),
        imageWrapper: cn('image-wrapper'),
        cropperWrapper: cn('cropper-wrapper')
      };
    },
    stencilCoordinates() {
      if (this.initialized) {
        const { width, height, left, top } = this.coordinates;
        return {
          width: width / this.coefficient,
          height: height / this.coefficient,
          left: (left - this.visibleArea.left) / this.coefficient,
          top: (top - this.visibleArea.top) / this.coefficient
        };
      }
      return this.defaultCoordinates();
    },
    boundariesStyle() {
      const styles = {
        width: this.boundaries.width ? `${Math.round(this.boundaries.width)}px` : 'auto',
        height: this.boundaries.height ? `${Math.round(this.boundaries.height)}px` : 'auto',
        transition: `opacity ${this.transitionTime}ms`,
        pointerEvents: this.imageLoaded ? 'all' : 'none'
      };
      if (!this.imageLoaded) {
        styles.opacity = '0';
      }
      return styles;
    },
    foregroundStyle() {
      const styles = {
        width: this.boundaries.width ? `${Math.round(this.boundaries.width) + this.margin}px` : 'auto',
        height: this.boundaries.height ? `${Math.round(this.boundaries.height) + this.margin}px` : 'auto',
        transition: `opacity ${this.transitionTime}ms`,
        pointerEvents: this.imageLoaded ? 'all' : 'none'
      };
      if (!this.imageLoaded) {
        styles.opacity = '0';
      }
      return styles;
    },
    imageStyle() {
      const optimalImageSize =
        this.imageAttributes.width > this.imageAttributes.height
          ? {
            width: Math.min(1024, this.imageAttributes.width),
            height:
                Math.min(1024, this.imageAttributes.width)
                / (this.imageAttributes.width / this.imageAttributes.height)
          }
          : {
            height: Math.min(1024, this.imageAttributes.height),
            width:
                Math.min(1024, this.imageAttributes.height)
                * (this.imageAttributes.width / this.imageAttributes.height)
          };

      const compensations = {
        rotate: {
          left: (optimalImageSize.width - this.imageSize.width) / (2 * this.coefficient),
          top: (optimalImageSize.height - this.imageSize.height) / (2 * this.coefficient)
        },
        scale: {
          left: ((1 - 1 / this.coefficient) * optimalImageSize.width) / 2,
          top: ((1 - 1 / this.coefficient) * optimalImageSize.height) / 2
        }
      };

      const transforms = {
        ...this.imageTransforms,
        scaleX: this.imageTransforms.scaleX * (this.imageAttributes.width / optimalImageSize.width),
        scaleY: this.imageTransforms.scaleY * (this.imageAttributes.height / optimalImageSize.height)
      };

      const result = {
        width: `${optimalImageSize.width}px`,
        height: `${optimalImageSize.height}px`,
        left: '0px',
        top: '0px',
        transform:
          `translate(${
            -compensations.rotate.left - compensations.scale.left - this.imageTransforms.translateX
          }px, ${-compensations.rotate.top - compensations.scale.top - this.imageTransforms.translateY}px)`
          + getStyleTransforms(transforms)
      };

      if (this.transitionsOptions.enabled) {
        result.transition = `${this.transitionsOptions.time}ms ${this.transitionsOptions.timingFunction}`;
      }
      return result;
    }
  },
  watch: {
    src() {
      this.onChangeImage();
    },
    aspectRatio() {
      this.$nextTick(this.onPropsChange);
    }
  },
  created() {
    this.debouncedUpdate = debounce(this.update, this.debounce);
    this.debouncedDisableTransitions = debounce(this.disableTransitions, this.transitionsOptions.time);
    this.awaiting = false;
  },
  mounted() {
    this.$refs.image.addEventListener('load', this.onSuccessLoadImage);
    this.$refs.image.addEventListener('error', this.onFailLoadImage);
    this.onChangeImage();

    // Add listeners to window to adapt the cropper to window changes
    window.addEventListener('resize', this.refresh);
    window.addEventListener('orientationchange', this.refresh);
  },
  unmounted() {
    window.removeEventListener('resize', this.refresh);
    window.removeEventListener('orientationchange', this.refresh);
    if (this.imageAttributes.revoke && this.imageAttributes.src) {
      URL.revokeObjectURL(this.imageAttributes.src);
    }
    this.debouncedUpdate.clear();
    this.debouncedDisableTransitions.clear();
  },
  methods: {
    // External methods
    getResult() {
      const coordinates = this.initialized
        ? this.prepareResult({ ...this.coordinates })
        : this.defaultCoordinates();
      const imageTransforms = {
        rotate: this.imageTransforms.rotate % 360,
        flip: {
          ...this.imageTransforms.flip
        }
      };
      if (this.src && this.imageLoaded) {
        return {
          image: this.image,
          coordinates,
          visibleArea: this.visibleArea ? { ...this.visibleArea } : null,
          imageTransforms
        };
      }
      return {
        image: this.image,
        coordinates,
        visibleArea: this.visibleArea ? { ...this.visibleArea } : null,
        imageTransforms
      };

    },
    zoom(factor, center, params = {}) {
      const { transitions = true } = params;

      this.onManipulateImage(
        new ManipulateImageEvent(
          {},
          {
            factor: 1 / factor,
            center
          }
        ),
        {
          normalize: false,
          transitions
        }
      );
    },
    move(left, top, params = {}) {
      const { transitions = true } = params;

      this.onManipulateImage(
        new ManipulateImageEvent({
          left: left || 0,
          top: top || 0
        }),
        {
          normalize: false,
          transitions
        }
      );
    },
    setCoordinates(transforms, params = {}) {
      const { transitions = true } = params;
      this.$nextTick(() => {
        if (!this.imageLoaded) {
          this.delayedTransforms = transforms;
        } else {
          if (!this.transitionsActive) {
            if (transitions) {
              this.enableTransitions();
            }
            this.coordinates = this.applyTransform(transforms);
            if (transitions) {
              this.debouncedDisableTransitions();
            }
          }
          this.onChange();
        }
      });
    },
    refresh() {
      const image = this.$refs.image;
      if (this.src && image) {
        if (this.initialized) {
          return this.updateVisibleArea().then(() => {
            this.onChange();
          });
        }

        return this.resetVisibleArea().then(() => {
          this.onChange();
        });
      }

      return null;
    },
    reset() {
      return this.resetVisibleArea().then(() => {
        this.setCoordinates(({ imageSize }) => ({
          top: 0,
          left: 0,
          width: imageSize.width,
          height: imageSize.height
        }), { transitions: true });
        this.onChange(false);
      });
    },
    // Internal methods
    awaitRender(callback) {
      if (!this.awaiting) {
        this.awaiting = true;
        this.$nextTick(() => {
          callback();
          this.awaiting = false;
        });
      }
    },
    prepareResult(coordinates) {
      return algorithms.roundCoordinates({
        ...this.getPublicProperties(),
        coordinates
      });
    },
    normalizeEvent(event) {
      return algorithms.normalizeEvent({
        ...this.getPublicProperties(),
        event
      });
    },
    update() {
      this.$emit('change', this.getResult());
    },
    applyTransform(transform, limited = false) {
      const sizeRestrictions = this.visibleArea && limited
        ? limitSizeRestrictions(this.sizeRestrictions, this.visibleArea)
        : this.sizeRestrictions;

      return algorithms.applyTransform({
        transform,
        coordinates: this.coordinates,
        imageSize: this.imageSize,
        sizeRestrictions,
        aspectRatio: this.getAspectRatio(),
        visibleArea: this.visibleArea
      });
    },
    resetCoordinates() {
      // This function can be asynchronously called after completion of refreshing image promise
      // Therefore there is a workaround to prevent processing after the component was unmounted
      // Also coordinates can't be reset if visible area was not initialized
      if (this.$refs.image) {

        const { minWidth, minHeight, maxWidth, maxHeight } = this.sizeRestrictions;

        const defaultSize = algorithms.defaultSize({
          boundaries: this.boundaries,
          imageSize: this.imageSize,
          aspectRatio: this.getAspectRatio(),
          sizeRestrictions: this.sizeRestrictions,
          visibleArea: this.visibleArea
        });

        if (
          process.env.NODE_ENV === 'development'
          && (defaultSize.width < minWidth
            || defaultSize.height < minHeight
            || defaultSize.width > maxWidth
            || defaultSize.height > maxHeight)
        ) {
          console.warn(
            'Warning: the default size breaks size restrictions. Check your defaultSize function',
            defaultSize,
            this.sizeRestrictions
          );
        }

        const transforms = [
          defaultSize,
          ({ coordinates }) => ({
            ...algorithms.defaultPosition({
              coordinates,
              imageSize: this.imageSize,
              visibleArea: this.visibleArea
            })
          })
        ];

        if (this.delayedTransforms) {
          transforms.push(
            ...(Array.isArray(this.delayedTransforms) ? this.delayedTransforms : [ this.delayedTransforms ])
          );
        }
        this.coordinates = this.applyTransform(transforms, true);
        this.delayedTransforms = null;
      }
    },
    clearImage() {
      this.imageLoaded = false;
      setTimeout(() => {
        const stretcher = this.$refs.stretcher;
        if (stretcher) {
          stretcher.style.height = 'auto';
          stretcher.style.width = 'auto';
        }
        this.coordinates = this.defaultCoordinates();
        this.boundaries = {
          width: 0,
          height: 0
        };
      }, this.transitionTime);
    },
    enableTransitions() {
      if (this.transitions) {
        this.transitionsActive = true;
      }
    },
    disableTransitions() {
      this.transitionsActive = false;
    },
    updateBoundaries() {
      const stretcher = this.$refs.stretcher;
      const cropper = this.$refs.cropper;

      algorithms.initStretcher({
        cropper,
        stretcher,
        imageSize: this.imageSize
      });

      return this.$nextTick().then(() => {
        const params = {
          cropper,
          imageSize: this.imageSize
        };

        this.boundaries = fillBoundaries(params);

        if (!this.boundaries.width || !this.boundaries.height) {
          throw new Error("It's impossible to fit the cropper in the current container");
        }
      });
    },
    resetVisibleArea() {
      // Reset the applied image transforms first to recalculate the image size correctly
      this.appliedImageTransforms = {
        ...this.defaultImageTransforms,
        flip: {
          ...this.defaultImageTransforms.flip
        }
      };
      return this.updateBoundaries()
        .then(() => {
          this.visibleArea = null;
          this.resetCoordinates();

          this.visibleArea = algorithms.defaultVisibleArea({
            imageSize: this.imageSize,
            boundaries: this.boundaries,
            coordinates: this.coordinates
          });

          this.visibleArea = algorithms.refineVisibleArea({
            visibleArea: this.visibleArea,
            boundaries: this.boundaries
          });

          this.coordinates = algorithms.fitCoordinates({
            visibleArea: this.visibleArea,
            coordinates: this.coordinates,
            aspectRatio: this.getAspectRatio(),
            sizeRestrictions: this.sizeRestrictions
          });
        })
        .catch(() => {
          this.visibleArea = null;
        });
    },
    updateVisibleArea() {
      return this.updateBoundaries()
        .then(() => {
          this.visibleArea = algorithms.fitVisibleArea({
            imageSize: this.imageSize,
            boundaries: this.boundaries,
            visibleArea: this.visibleArea,
            coordinates: this.coordinates
          });
          this.coordinates = algorithms.fitCoordinates({
            visibleArea: this.visibleArea,
            coordinates: this.coordinates,
            aspectRatio: this.getAspectRatio(),
            sizeRestrictions: this.sizeRestrictions
          });
        })
        .catch(() => {
          this.visibleArea = null;
        });
    },
    onChange(_debounce = true) {
      if (_debounce && this.debounce) {
        this.debouncedUpdate();
      } else {
        this.update();
      }
    },
    onChangeImage() {
      this.imageLoaded = false;
      this.delayedTransforms = null;

      if (this.src) {
        setTimeout(() => {
          this.onParseImage({ source: this.src });
        }, this.transitionTime);
      } else {
        this.clearImage();
      }
    },
    onFailLoadImage() {
      if (this.imageAttributes.src) {
        this.clearImage();
        this.$emit('error');
      }
    },
    onSuccessLoadImage() {
      // After loading image the current component can be unmounted
      // Therefore there is a workaround to prevent processing the following code
      const image = this.$refs.image;
      if (image && !this.imageLoaded) {
        this.imageAttributes.height = image.naturalHeight;
        this.imageAttributes.width = image.naturalWidth;
        this.imageLoaded = true;
        this.resetVisibleArea().then(() => {
          this.$emit('ready');
          this.onChange(false);
        });
      }
    },
    onParseImage({ source }) {
      if (this.imageAttributes.revoke && this.imageAttributes.src) {
        URL.revokeObjectURL(this.imageAttributes.src);
      }
      this.imageAttributes.revoke = false;

      this.imageAttributes.src = source;

      this.$nextTick(() => {
        const image = this.$refs.image;
        if (image && image.complete) {
          if (isLoadedImage(image)) {
            this.onSuccessLoadImage();
          } else {
            this.onFailLoadImage();
          }
        }
      });
    },
    onMove(event) {
      if (!this.transitionsOptions.enabled) {
        this.awaitRender(() => {
          this.coordinates = algorithms.move({
            ...this.getPublicProperties(),
            coordinates: this.coordinates,
            event: this.normalizeEvent(event)
          });
          this.onChange();
        });
      }
    },
    onResize(event) {
      if (!this.transitionsOptions.enabled) {
        this.awaitRender(() => {
          const sizeRestrictions = this.sizeRestrictions;

          // The magic number is the approximation of the handler size
          // Temporary solution that should be improved in the future
          const minimumSize = Math.min(
            this.coordinates.width,
            this.coordinates.height,
            20 * this.coefficient
          );

          this.coordinates = algorithms.resize({
            ...this.getPublicProperties(),
            sizeRestrictions: {
              maxWidth: Math.min(sizeRestrictions.maxWidth, this.visibleArea.width),
              maxHeight: Math.min(sizeRestrictions.maxHeight, this.visibleArea.height),
              minWidth: Math.max(sizeRestrictions.minWidth, minimumSize),
              minHeight: Math.max(sizeRestrictions.minHeight, minimumSize)
            },
            event: this.normalizeEvent(event)
          });
          this.onChange();
          this.ticking = false;
        });
      }
    },
    onManipulateImage(event, params = {}) {
      if (!this.transitionsOptions.enabled) {
        const { transitions = false, normalize = true } = params;
        if (transitions) {
          this.enableTransitions();
        }
        const { visibleArea, coordinates } = algorithms.manipulateImage({
          ...this.getPublicProperties(),
          event: normalize ? this.normalizeEvent(event) : event,
          adjustStencil: this.settings.resizeImage.adjustStencil
        });

        this.visibleArea = visibleArea;
        this.coordinates = coordinates;

        this.onChange();

        if (transitions) {
          this.debouncedDisableTransitions();
        }
      }
    },
    onPropsChange() {
      this.coordinates = this.applyTransform(this.coordinates, true);
      this.onChange(false);
    },
    getAspectRatio() {
      return {
        minimum: this.aspectRatio,
        maximum: this.aspectRatio
      };
    },
    getPublicProperties() {
      return {
        coefficient: this.coefficient,
        visibleArea: this.visibleArea,
        coordinates: this.coordinates,
        boundaries: this.boundaries,
        sizeRestrictions: this.sizeRestrictions,
        aspectRatio: this.getAspectRatio()
      };
    },
    defaultCoordinates() {
      return { ...DEFAULT_COORDINATES };
    },
    flip(horizontal, vertical, options = {}) {
      const { transitions = true } = options;
      if (!this.transitionsActive) {
        if (transitions) {
          this.enableTransitions();
        }

        const previousFlip = {
          ...this.imageTransforms.flip
        };

        const { visibleArea, coordinates } = algorithms.flipImage({
          flip: {
            horizontal: horizontal ? !previousFlip.horizontal : previousFlip.horizontal,
            vertical: vertical ? !previousFlip.vertical : previousFlip.vertical
          },
          previousFlip,
          rotate: this.imageTransforms.rotate,
          visibleArea: this.visibleArea,
          coordinates: this.coordinates,
          imageSize: this.imageSize,
          sizeRestrictions: this.sizeRestrictions,
          aspectRatio: this.getAspectRatio()
        });

        if (horizontal) {
          this.appliedImageTransforms.flip.horizontal = !this.appliedImageTransforms.flip.horizontal;
        }
        if (vertical) {
          this.appliedImageTransforms.flip.vertical = !this.appliedImageTransforms.flip.vertical;
        }

        this.visibleArea = visibleArea;
        this.coordinates = coordinates;

        this.onChange();
        if (transitions) {
          this.debouncedDisableTransitions();
        }
      }
    },
    rotate(angle, options = {}) {
      const { transitions = true } = options;

      if (!this.transitionsActive) {
        if (transitions) {
          this.enableTransitions();
        }
        const previousImageSize = { ...this.imageSize };

        this.appliedImageTransforms.rotate += angle;
        const { visibleArea, coordinates } = algorithms.rotateImage({
          visibleArea: this.visibleArea,
          coordinates: this.coordinates,
          previousImageSize,
          imageSize: this.imageSize,
          angle,
          sizeRestrictions: this.sizeRestrictions,
          aspectRatio: this.getAspectRatio()
        });

        this.visibleArea = visibleArea;
        this.coordinates = coordinates;

        this.onChange();

        if (transitions) {
          this.debouncedDisableTransitions();
        }
      }
    },
    setAngle(angle) {
      this.appliedImageTransforms.rotate = angle;
      this.onChange();
    }
  }
};
</script>

<template>
  <div ref="cropper" :class="classes.cropper">
    <div ref="stretcher" :class="classes.stretcher" />

    <div :class="classes.boundaries" :style="boundariesStyle">
      <CropperBackground
        :class="classes.cropperWrapper"
        :wheel-resize="settings.resizeImage.wheel"
        :touch-resize="settings.resizeImage.touch"
        :touch-move="settings.moveImage.touch"
        :mouse-move="settings.moveImage.mouse"
        :image="image"
        @move="onManipulateImage"
        @resize="onManipulateImage"
      >
        <div :class="classes.background" :style="boundariesStyle" />
        <div :class="classes.imageWrapper">
          <img
            ref="image"
            :src="imageAttributes.src"
            :class="classes.image"
            :style="imageStyle"
            @mousedown.prevent
          >
        </div>
        <div :class="classes.foreground" :style="foregroundStyle" />
        <RectangleStencil
          v-show="imageLoaded"
          ref="stencil"
          :image="image"
          :coordinates="coordinates"
          :stencil-coordinates="stencilCoordinates"
          :transitions="transitionsOptions"
          :aspect-ratio="aspectRatio"
          @resize="onResize"
          @move="onMove"
        />
      </CropperBackground>
    </div>
  </div>
</template>

<style lang="scss">
.vue-advanced-cropper {
  text-align: center;
  position: relative;
  user-select: none;
  max-height: 100%;
  max-width: 100%;
  direction: ltr;

  &__stretcher {
    pointer-events: none;
    position: relative;
    max-width: 100%;
    max-height: 100%;
  }

  &__image {
    user-select: none;
    position: absolute;
    transform-origin: center;
    // Workaround to prevent bugs at the websites with max-width
    // rule applied to img (Vuepress for example)
    max-width: none !important;
  }
  &__background,
  &__foreground {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &__foreground {
    background: black;
    opacity: 0.5;
  }
  &__boundaries {
    opacity: 1;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  &__cropper-wrapper {
    width: 100%;
    height: 100%;
  }
  &__image-wrapper {
    // overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &__stencil-wrapper {
    position: absolute;
  }
}
</style>
