export class DehancerServerError extends Error {
  json: any | null = null;
  httpStatus: number | null = null;

  constructor(json: any, httpStatus: number | null) {
    super(json?.error || '(unknown error)');

    this.name = 'DehancerServerError';
    this.json = json;
    this.httpStatus = httpStatus;
  }

  static possiblyThrowError(json: any, httpStatus: number | null) {
    if (this.isError(json, httpStatus)) {
      throw new DehancerServerError(json, httpStatus);
    }
  }

  static isError(json: any, httpStatus: number | null) {
    return !json || json?.error || httpStatus !== 200;
  }
}
