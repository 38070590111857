import {
  close as closeLockScreen,
  progress as lockScreenProgress,
  message as lockScreenMessage,
  shouldShowButton as shouldShowLockScreenButton,
  buttonCallback as lockScreenButtonCallback
} from './lockScreen.js';
import { open as showErrorPage } from './errorPage.js';
import { RenderFormats } from './paneConstants.js';

let blobUrl: string | null = null;

// declare const window: Window & { dataLayer: Record<string, unknown>[]; };

export async function downloadUrl(url: string, filename: string, format: RenderFormats, onSuccessCallback: Function) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.withCredentials = true;

  xhr.onprogress = event => {
    if (!event.lengthComputable) {
      return;
    }

    lockScreenProgress.value = (event.loaded / event.total) * 100;
  };

  xhr.onload = () => {
    if (xhr.status !== 200) {
      lockScreenProgress.value = null;
      closeLockScreen();
      showErrorPage("Oops", "Download error", "Something wrong happened during downloading of JPEG (http " + xhr.status + ")");
      revokeBlobURLSoon();
      return;
    }

    blobUrl = URL.createObjectURL(xhr.response);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = filename;
    a.click();

    lockScreenProgress.value = null;
    lockScreenMessage.value = "The photo has been downloaded to your device";

    shouldShowLockScreenButton.value = true;
    lockScreenButtonCallback.value = () => {
      closeLockScreen();
      lockScreenProgress.value = null;
      revokeBlobURLSoon();
      onSuccessCallback();
    };

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'export',
        format
      });
    }

    if (window._paq) {
      window._paq.push([ 'trackEvent', 'Dehancer', 'Export', format ]);
    }
  };

  xhr.send();
}

export function revokeBlobURL() {
  if (!blobUrl) {
    return;
  }

  URL.revokeObjectURL(blobUrl);

  blobUrl = null;
}

export function revokeBlobURLSoon() {
  setTimeout(revokeBlobURL, 150);
}
