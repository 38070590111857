<template>
  <div class="w-full min-h-full flex flex-col justify-center items-center bg-blueprint">
    <div class="shrink-0 w-full bg-main text-white/60 shadow-lg z-20">
      <div class="inline-flex items-center mr-auto px-1 xs:pr-6 ml-4 md:ml-10" :class="isMobile ? 'h-12' : 'h-16 md:h-[76px]'">
        <the-logo-text class="h-8 md:h-10" />
      </div>
    </div>
    <div class="grow flex flex-col justify-start items-center pt-2 tall:pt-[10%]">
      <div class="py-4 space-y-4 tall:py-8 tall:space-y-8 bg-main rounded-2xl text-center px-8 xs:tall:px-16">
        <div class="tall:max-w-52 mx-auto">Authorize this device</div>

        <form class="tall:max-w-52 mx-auto" @submit.prevent="validate">
          <div class="text-xs tall:text-sm w-full mb-4 tall:mb-8">
            Please enter the code that has been emailed to you at {{ email }}
          </div>

          <div class="max-w-52 tall:max-w-none mx-auto w-full relative">
            <input
              ref="input"
              v-model="code"
              inputmode="numeric"
              pattern="\d*"
              maxlength="6"
              type="text"
              class="w-full rounded-xl text-center bg-black p-2 placeholder:text-[#9F9A92] outline-none border"
              :class="isCodeCheckFailed ? 'border-alert text-alert' : 'border-black focus-visible:border-pale text-[#FFFAF1]'"
              placeholder="XXXXXX"
              data-1p-ignore="true"
              @focus="isCodeCheckFailed = false"
            >
            <button v-if="code" type="button" class="absolute right-0 inset-y-0 w-10 text-[#538DA5]" @click="clearInput">&times;</button>
          </div>

          <button type="submit" :disabled="!isConfirmButtonEnabled" class="btn max-w-52 tall:max-w-none mx-auto w-full mt-3">Confirm</button>
        </form>

        <div class="text-sm">
          <div v-if="isCodeCheckFailed" class="text-alert w-screen max-w-64">The code is incorrect.<br>Try again or request a new one.</div>

          <div v-else class="tall:max-w-64 text-white/50">
            Didn't get the email? Check your spam folder or request code again.
          </div>

          <div>
            <button type="button" class="link mt-2 tall:mt-4" :disabled="!isSendCodeButtonEnabled" @click.prevent="send">
              Send again
              <template v-if="sendCodeTimerValue! > 0">
                ({{ sendCodeTimerValue }})
              </template>
            </button>
          </div>
          <div v-if="isCodeSendFailed" class="text-danger mt-2 max-w-64">{{ codeSendError }}</div>

          <div class="mt-4">
            <a href="/logout" class="link mt-2 tall:mt-4 ">Sign&nbsp;out</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, shallowRef, ref, computed, onMounted, Ref, ShallowRef } from 'vue';
import { API_PREFIX } from '../constants';
import { post } from '../post';
import { isWorkstationAuthorized } from '../auth';
import { isMobile } from '../breakpoints';
import TheLogoText from '../components/icons/TheLogoText.vue';
import { email } from '../user';

const input: Ref<HTMLInputElement | null> = ref(null);

const CODE_SEND_ERROR_FAILED = 'Failed sending code :-(';
const CODE_SEND_ERROR_THROTTLED = 'You should wait a little bit more until requesting the code again.';

const codeSendError = shallowRef('');
const isCodeSendFailed = shallowRef(false);
const isCodeCheckFailed = shallowRef(false);
const isSendCodeButtonEnabled = shallowRef(true);
const code = shallowRef('');
const isValidateInProgress = shallowRef(false);

const isConfirmButtonEnabled = computed(() => !isValidateInProgress.value && code.value.match(/^\d{6}$/));

const sendCodeTimerValue: ShallowRef<number | null> = shallowRef(null);
let sendCodeTimerInterval: number | null = null;

async function validate() {
  isCodeCheckFailed.value = false;
  isValidateInProgress.value = true;

  const { json } = await post({
    url: API_PREFIX + '/auth/workstation/validate-code',
    data: {
      code: code.value
    }
  });

  isValidateInProgress.value = false;

  if (json?.success) {
    isWorkstationAuthorized.value = true;
    return;
  }

  isCodeCheckFailed.value = true;
}

async function send() {
  code.value = '';
  isCodeSendFailed.value = false;
  isCodeCheckFailed.value = false;
  isSendCodeButtonEnabled.value = false;

  const { json, status } = await post({
    url: API_PREFIX + '/auth/workstation/send-code',
    data: {}
  });

  if (status === 429) {
    isSendCodeButtonEnabled.value = true;
    codeSendError.value = CODE_SEND_ERROR_THROTTLED;
    isCodeSendFailed.value = true;
    return;
  }

  if (!json?.success) {
    isSendCodeButtonEnabled.value = true;
    codeSendError.value = CODE_SEND_ERROR_FAILED;
    isCodeSendFailed.value = true;
    return;
  }

  sendCodeTimerValue.value = 40;
  sendCodeTimerInterval = setInterval(() => {
    sendCodeTimerValue.value!--;

    if (sendCodeTimerValue.value! > 0) {
      return;
    }

    isSendCodeButtonEnabled.value = true;

    clearInterval(sendCodeTimerInterval!);
    sendCodeTimerInterval = null;
  }, 1000);
}

onMounted(send);

onBeforeUnmount(() => {
  if (sendCodeTimerInterval) {
    clearInterval(sendCodeTimerInterval);
    sendCodeTimerInterval = null;
  }
});

function clearInput() {
  code.value = '';
  isCodeCheckFailed.value = false;
  input.value!.focus();
}
</script>
