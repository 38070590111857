<template>
  <form>
    <template v-if="!isSigninOrSignupShown">
      <div class="text-lg text-[#FFFAF1] w-full mb-8">
        Please enter your email:
      </div>

      <input
        v-model="email"
        v-focus
        type="email"
        placeholder="email"
        class="w-full rounded-xl bg-black px-4 py-2 placeholder:text-[#9F9A92] placeholder:text-center outline-none border"
        :class="error ? 'border-alert text-alert' : 'border-black focus-visible:border-pale text-[#FFFAF1]'"
        :disabled="isLoading"
      >

      <div class="text-center">
        <button type="submit" class="btn max-w-52 tall:max-w-none mx-auto w-full mt-3" :disabled="isLoading || !isEmailValid" @click.prevent="send({ type: 'submit' })">Next</button>
      </div>

      <error-or-throttled :error="error" :is-throttled="isThrottled" />
    </template>

    <template v-else-if="isSigninOrSignupShown === 'signin'">
      <div class="text-lg text-[#FFFAF1] w-full mb-2">
        Sign&nbsp;In as:
      </div>
      <div class="text-center text-success mb-6">
        {{ email }}
      </div>

      <template v-if="snapshot.matches({ signin: 'pendingPasswordInput' }) || snapshot.matches({ signin: 'loginWithEmailAndPasswordInProgress' })">
        <input
          v-model="password"
          v-focus
          type="password"
          placeholder="password"
          class="w-full rounded-xl bg-black px-4 py-2 placeholder:text-[#9F9A92] placeholder:text-center outline-none border"
          :class="error ? 'border-alert text-alert' : 'border-black focus-visible:border-pale text-[#FFFAF1]'"
          :disabled="isLoading"
        >
      </template>

      <template v-else>
        <div class="text-lg text-[#FFFAF1] w-full mb-2">
          Password:
        </div>
        <div class="text-center text-success mb-6">
          *********
        </div>
      </template>

      <div class="text-center">
        <button type="submit" class="btn max-w-52 tall:max-w-none mx-auto w-full mt-3" :disabled="isLoading || !isPasswordValid" @click.prevent="send({ type: 'submit' })">Next</button>
      </div>

      <error-or-throttled :error="error" :is-throttled="isThrottled" />

      <div class="text-center mt-4">
        <a class="link" :href="resetPasswordUrl">Forgot password?</a>
      </div>
    </template>

    <template v-else-if="isSigninOrSignupShown === 'signup'">
      <div class="text-lg text-[#FFFAF1] w-full mb-2">
        New user registration:
      </div>
      <div class="text-lg text-center mb-6 text-success">
        {{ email }}
      </div>

      <template v-if="snapshot.matches({ signup: 'pendingPasswordInput' }) || snapshot.matches({ signup: 'registerWithEmailAndPasswordInProgress' })">
        <input
          v-model="password"
          v-focus
          type="password"
          placeholder="Create password"
          class="w-full rounded-xl bg-black px-4 py-2 placeholder:text-[#9F9A92] placeholder:text-center outline-none border"
          :class="error ? 'border-alert text-alert' : 'border-black focus-visible:border-pale text-[#FFFAF1]'"
          :disabled="isLoading"
        >

        <div class="text-center">
          <button type="submit" class="btn max-w-52 tall:max-w-none mx-auto w-full mt-3" :disabled="isLoading || !isPasswordValid" @click="send({ type: 'submit' })">Next</button>
        </div>
      </template>

      <template v-else>
        <div class="text-lg text-[#FFFAF1] w-full mb-2">
          Password:
        </div>
        <div class="text-lg text-center mb-6 text-success">
          *********
        </div>
      </template>

      <template v-if="snapshot.matches({ signup: 'pendingVerificationTokenInput' }) || snapshot.matches({ signup: 'loginWithTokenInProgress' })">
        <div class="text-lg text-[#FFFAF1] w-full mb-2">
          Confirmation code:
        </div>
        <input
          v-model="token"
          v-focus
          type="text"
          placeholder="XXXXXX"
          inputmode="numeric"
          pattern="\d*"
          maxlength="6"
          data-1p-ignore="true"
          class="w-full rounded-xl bg-black px-4 py-2 placeholder:text-[#9F9A92] placeholder:text-center outline-none border"
          :class="error ? 'border-alert text-alert' : 'border-black focus-visible:border-pale text-[#FFFAF1]'"
          :disabled="isLoading"
        >
        <div class="text-lg text-sm text-pale mt-1 w-full mb-2">
          (it was sent to your email)
        </div>

        <div class="text-center">
          <button type="submit" class="btn max-w-52 tall:max-w-none mx-auto w-full mt-3" :disabled="isLoading || !isTokenValid" @click="send({ type: 'submit' })">Next</button>
        </div>
      </template>

      <error-or-throttled :error="error" :is-throttled="isThrottled" />
    </template>
  </form>
</template>

<script setup lang="ts">
import { computed, ShallowRef, shallowRef, watch } from 'vue';
import { fromPromise } from 'xstate';
import { useMachine } from '@xstate/vue';
import { post } from '../post';
import { API_PREFIX } from '../constants';
import { DehancerServerError } from '../DehancerServerError';
import { closeLogin, purchaseAfterLoginData } from '../auth';
import { fetchWhoAmI, isActive } from '../user';
// @ts-ignore xstate
import { machine as loginStateMachine } from '../loginStateMachine.js';
import ErrorOrThrottled from './signup-block/ErrorOrThrottled.vue';

const isThrottled = shallowRef(false);
const error: ShallowRef<string | null> = shallowRef(null);

const email = shallowRef('');
const password = shallowRef('');
const token = shallowRef('');
const isLoading = shallowRef(false);

type SigninOrSignup = 'signin' | 'signup';
const isSigninOrSignupShown: ShallowRef<SigninOrSignup | null> = shallowRef(null);

const currentStep = shallowRef(0);
watch(currentStep, () => emit('current-step-changed', currentStep.value));

// @ts-ignore xstate
const { snapshot, send, actorRef } = useMachine(
  loginStateMachine.provide({
    actions: {
      showSignup: () => isSigninOrSignupShown.value = 'signup',

      showSignin: () => isSigninOrSignupShown.value = 'signin',

      showError: ({ event }: { event: any}, params: any) => {
        isThrottled.value = false;
        error.value = null;

        if (event.error instanceof DehancerServerError && event.error.httpStatus === 429) {
          isThrottled.value = true;
          return;
        }

        error.value = event.error?.message || params?.error || '(unknown error)';
      },

      hideError: () => {
        error.value = null;
        isThrottled.value = false;
      },

      closeWindow: () => closeLogin(),

      changeStepsForSignup: () => {
        const steps = [
          'Email',
          'Password',
          'Validate'
        ];

        if (purchaseAfterLoginData.value) {
          steps.push('Purchase');
        }

        emit('steps-changed', steps);
      },

      changeStepsForSignin: () => {
        // empty because steps are already set in the parent component corresponding for signin state
      },
      incrementStep: () => currentStep.value++,
      decrementStep: () => currentStep.value++,

      sendGaEvent: (_: any, params: any) => {
        if (!window.dataLayer) {
          return;
        }

        window.dataLayer.push(params);
      }
    },

    actors: {
      checkEmail: fromPromise(async () => {
        const { json, status } = await post({
          url: API_PREFIX + '/auth/check-email',
          data: {
            email: email.value
          }
        });

        DehancerServerError.possiblyThrowError(json, status);

        return json;
      }),

      fetchWhoAmI: fromPromise(async () => {
        await fetchWhoAmI();
      }),

      loginWithEmailAndPassword: fromPromise(async () => {
        isThrottled.value = false;

        const { json, status } = await post({
          url: API_PREFIX + '/auth/login-with-email-and-password',
          data: {
            email: email.value,
            password: password.value
          }
        });

        DehancerServerError.possiblyThrowError(json, status);

        return json;
      }),

      registerWithEmailAndPassword: fromPromise(async () => {
        const { json, status } = await post({
          url: API_PREFIX + '/auth/register-with-email-and-password',
          data: {
            email: email.value,
            password: password.value
          }
        });

        DehancerServerError.possiblyThrowError(json, status);

        return json;
      }),

      loginWithToken: fromPromise(async () => {
        const { json, status } = await post({
          url: API_PREFIX + '/auth/login-with-token',
          data: {
            email: email.value,
            token: token.value
          }
        });

        DehancerServerError.possiblyThrowError(json, status);

        return json;
      }),

      createCheckoutSessionUrl: fromPromise(async () => {
        const { json } = await post({
          url: API_PREFIX + '/billing/purchase',
          data: purchaseAfterLoginData.value
        });

        if (!json?.url) {
          return;
        }

        window.location.href = json.url;
        await new Promise(() => {}); // eternal sleep
      })
    },

    guards: {
      isEmailExisting: ({ event }: { event: any }) => event.output?.success && event.output.isExisting,
      isSuccess: ({ event }: { event: any }) => event.output?.success,
      isPurchasePending: () => Boolean(purchaseAfterLoginData.value) && !isActive.value
    }
  })
);

actorRef.subscribe(state => {
  // should be already closed by the entry action 'closeWindow' on the `loggedIn` state
  // if (state.matches('loggedIn')) {
  //   closeLogin();
  //   return;
  // }

  const meta = state.getMeta();
  isLoading.value = Boolean(Object.values(meta).find((v:any) => v.isLoading));
});

const _resetPasswordUrl = new URL('https://www.dehancer.com/password/reset');
_resetPasswordUrl.searchParams.set('redirect', window.location.href);
const resetPasswordUrl = _resetPasswordUrl.toString();

const isEmailValid = computed(() => email.value.match(
  /* eslint-disable no-useless-escape */
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  /* eslint-enable no-useless-escape */
));
const isPasswordValid = computed(() => password.value.length >= 8);
const isTokenValid = computed(() => token.value.match(/^\d{6}$/));

const emit = defineEmits<{
  'steps-changed': [string[]]
  'current-step-changed': [number]
}>();
</script>
