<template>
  <svg viewBox="0 0 44 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="linearGradient-1d" x1="50%" y1="100%" x2="50%" y2="0%">
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="100%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="100%" />
      </linearGradient>
    </defs>
    <g id="btnAddHover" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(10.5, 10.5)">
        <path id="Rectangle-Copy" d="M9.49845689,0.537475586 L13.5015431,0.537475586 C16.6067184,0.537475586 17.7407436,0.862365225 18.8814844,1.47244005 C20.0222251,2.08251487 20.9174851,2.97777488 21.52756,4.11851562 C22.1376348,5.25925636 22.4625244,6.39328157 22.4625244,9.49845689 L22.4625244,13.5015431 C22.4625244,16.6067184 22.1376348,17.7407436 21.52756,18.8814844 C20.9174851,20.0222251 20.0222251,20.9174851 18.8814844,21.52756 C17.7407436,22.1376348 16.6067184,22.4625244 13.5015431,22.4625244 L9.49845689,22.4625244 C6.39328157,22.4625244 5.25925636,22.1376348 4.11851562,21.52756 C2.97777488,20.9174851 2.08251487,20.0222251 1.47244005,18.8814844 C0.862365225,17.7407436 0.537475586,16.6067184 0.537475586,13.5015431 L0.537475586,9.49845689 C0.537475586,6.39328157 0.862365225,5.25925636 1.47244005,4.11851562 C2.08251487,2.97777488 2.97777488,2.08251487 4.11851562,1.47244005 C5.25925636,0.862365225 6.39328157,0.537475586 9.49845689,0.537475586 Z" stroke="#A1A1A1" stroke-width="2" />
        <g transform="translate(10 4)" fill="#548FA7">
          <rect x=".262" y="5.571" width="2.476" height="8.667" rx="1.238" />
          <rect width="3" height="3" rx="1.5" />
        </g>
        <g transform="translate(10 4.5)" fill="url(#linearGradient-1d)">
          <rect x=".262" y="5.571" width="2.476" height="8.667" rx="1.238" />
          <rect width="3" height="3" rx="1.5" />
        </g>
      </g>
    </g>
  </svg>
</template>
