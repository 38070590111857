<template>
  <div class="w-full bg-blueprint selectable">
    <template v-if="isLoading">
      Loading...
    </template>

    <template v-else-if="error">
      Failed loading user: {{ error }}
    </template>

    <template v-else>
      <dl class="divide-y divide-gray-600 tabular-nums">
        <description-list-entry title="id" :value="entry.id" />
        <description-list-entry title="Event" :value="entry.eventType" />
        <description-list-entry title="Created" html>
          {{ formatDateLocale(entry.createdAt) }}
          <span class="text-gray-500 pl-4">{{ formatDateUTC(entry.createdAt) }} UTC</span>
        </description-list-entry>

        <description-list-entry title="Details" :value="entry.details || '(null)'" />
        <description-list-entry title="Stripe customerId" html>
          <a class="link font-mono" :href="'https://dashboard.stripe.com/customers/' + entry.user?.stripeCustomerId">{{ entry.user?.stripeCustomerId }}</a>
        </description-list-entry>
        <description-list-entry title="userId, email" html>
          <router-link v-if="entry.user?.id" :to="{ name: 'adminUserPage', params: { userIdOrEmail: entry.user?.id } }" class="link font-mono">{{ entry.user?.id }}</router-link>
          <span class="ml-4">
            {{ entry.user?.email }}
          </span>
        </description-list-entry>
      </dl>
      <div class="p-8 font-mono border-t border-gray-600">
        <!-- eslint-disable vue/no-v-html -->
        <pre v-html="payloadHtml" />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import ky, { HTTPError } from 'ky';
import { formatDateLocale, formatDateUTC } from '../formatters';
import { Ref, ref, ShallowRef, shallowRef } from 'vue';
import DescriptionListEntry from '../components/admin/DescriptionListEntry.vue';
import hljs from 'highlight.js/lib/core';
import json from 'highlight.js/lib/languages/json';
import 'highlight.js/styles/github-dark.css';

hljs.registerLanguage('json', json);

const error: ShallowRef<string | null> = shallowRef('yes');
const isLoading = shallowRef(true);

// FIXME any
const entry: Ref<any, null> = ref(null);
const payloadHtml: ShallowRef<string | null> = shallowRef(null);

const { id } = defineProps<{
  id: string;
}>();

async function load() {
  error.value = null;
  isLoading.value = true;

  let jsonResponse: any;

  try {
    jsonResponse = await ky.get('/api/admin/stripe-webhook-log/' + id).json();

  } catch (e) {
    if (e instanceof HTTPError) {
      if (e.response.status === 401) {
        error.value = "Unauthorized";
        return;
      }
    }

    error.value = String(e);

  } finally {
    isLoading.value = false;
  }

  if (!jsonResponse?.success) {
    error.value = jsonResponse?.error || 'Unknown error';
    entry.value = null;
    return;
  }

  entry.value = jsonResponse.entry;

  payloadHtml.value = hljs.highlight(JSON.stringify(jsonResponse.entry.payload, null, "\t"), { language: 'json' }).value;
}

load();
</script>
