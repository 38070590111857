import ky, { HTTPError, TimeoutError } from 'ky';

export type PostResponse = {
  status: number | null;
  json: any;
};

export async function post({ url, data, isImageRequest = false }: { url: string, data: any, isImageRequest?: boolean }): Promise<PostResponse> {
  let retry = isImageRequest ? 2 : undefined;
  let timeout = isImageRequest ? 60000 : 10000;

  try {
    const json = await ky.post(url, { json: data, credentials: 'include', timeout, retry }).json();
    return { json, status: 200 };

  } catch (e) {
    console.error(e);

    if (e instanceof HTTPError) {
      return { status: e?.response?.status || null, json: null };
    }

    if (e instanceof TimeoutError) {
      return { status: 999, json: null };
    }

    return { status: null, json: null };
  }
}

