<template>
  <div class="w-full min-h-full flex flex-col justify-center items-center bg-blueprint">
    <div class="shrink-0 w-full bg-main text-white/60 shadow-lg z-20">
      <div class="inline-flex items-center mr-auto px-1 xs:pr-6 ml-4 md:ml-10" :class="isMobile ? 'h-12' : 'h-16 md:h-[76px]'">
        <the-logo-text class="h-8 md:h-10" />
      </div>
    </div>
    <div class="grow w-full flex flex-col justify-start items-center pt-[10%]">
      <div class="xs:bg-main rounded-2xl flex flex-col justify-center items-center space-y-8 w-5/6 max-w-80 aspect-square">
        <template v-if="state === STATE_CHECKING">
          <img src="../assets/icons/icnPaymentProcessing.png" alt="Processing" class="size-20 mx-auto mt-4">
          <div class="text-[#FFFAF1] animate-pulse">Processing payment...</div>
          <div>&nbsp;</div>
        </template>

        <template v-if="state === STATE_SUCCESS">
          <img src="../assets/icons/icnPaymentSuccess.png" alt="Payment successful" class="size-20 mx-auto mt-4">
          <div class="text-[#FFFAF1] text-center text-balance">Thank you for the purchase. Your subscription is now active!</div>
          <button class="link" @click="onOkayClick">Cool!</button>
        </template>

        <template v-else-if="state === STATE_ERROR">
          <img src="../assets/icons/icnPaymentFailed.png" alt="Payment error" class="size-20 mx-auto mt-4">
          <div class="text-[#FFFAF1] text-center">It seems that the payment failed. Get in touch with support.</div>
          <button class="link" @click="onOkayClick">Okay :-(</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheLogoText from '../components/icons/TheLogoText.vue';
import { onBeforeUnmount, onMounted, ShallowRef, shallowRef } from 'vue';
import { isMobile } from '../breakpoints';
import { API_PREFIX } from '../constants';

const TIMEOUT_MS = 4000;
const STATE_CHECKING = 0;
const STATE_SUCCESS = 1;
const STATE_ERROR = 2;

const state = shallowRef(STATE_CHECKING);
const imageId: ShallowRef<string | null> = shallowRef(null);

const { sessionId } = defineProps<{
  sessionId: string;
}>();

async function getStripeCheckSession() {
  try {
    const response = await fetch(API_PREFIX + '/billing/check-session/' + sessionId);
    const json = await response.json();

    return {
      json,
      status: response.status
    };

  } catch (e) {
    console.error(e);
    return null;
  }
}

let timeout: number | null = null;

async function cycleCheckSession() {
  timeout = null;

  const result = await getStripeCheckSession();
  if (!result) {
    timeout = setTimeout(cycleCheckSession, TIMEOUT_MS);
    return;
  }

  const { json, status } = result;

  if (status === 404) {
    state.value = STATE_ERROR;
    return;
  }

  if (json.success) {
    imageId.value = json.imageId;
    state.value = STATE_SUCCESS;

    if (window.dataLayer && json.dataLayer) {
      window.dataLayer.push(json.dataLayer);
    }

    if (window.gtag && json.gtagEventPurchase) {
      window.gtag('event', 'purchase', json.gtagEventPurchase);
    }

    if (window.gtag && json.gtagEventConversion) {
      window.gtag('event', 'conversion', json.gtagEventConversion);
    }

    if (window._paq && json.paq) {
      for (const entry of json.paq) {
        window._paq.push(entry);
      }
    }

    return;
  }

  timeout = setTimeout(cycleCheckSession, TIMEOUT_MS);
}

onBeforeUnmount(() => {
  if (timeout) {
    clearTimeout(timeout);
  }
});

onMounted(cycleCheckSession);

function onOkayClick() {
  if (imageId.value) {
    window.location.href = '/i/?i=' + imageId.value;
  } else {
    window.location.href = '/';
  }
}
</script>
