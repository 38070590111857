<template>
  <div class="w-full h-full flex flex-col relative bg-blueprint px-10 py-10 selectable overflow-auto tabular-nums">
    <div class="bg-main rounded-2xl px-10 py-8 space-y-4">
      <template v-if="isLoading">
        Loading...
      </template>

      <template v-else-if="error">
        Failed loading user: {{ error }}
      </template>

      <template v-else>
        <div>
          <span class="text-xl font-bold">
            <a class="link" :href="'mailto:' + user.email">{{ user.email }}</a>
          </span>

          <span /> &nbsp; <!-- because double click selects something else -->

          <span class="text-xl font-extralight font-mono ml-2">{{ user.id }}</span>
        </div>
        <dl class="divide-y divide-gray-600">
          <description-list-entry title="Created" :value="user.createdAt" />
          <description-list-entry title="Updated" :value="user.updatedAt" />
          <description-list-entry title="Max sessions" :value="user.maxSessionsCount" />
          <description-list-entry title="Total exports" :value="user.totalExportsCount" />

          <description-list-entry title="Stripe customer id" html>
            <a class="link font-mono" :href="'https://dashboard.stripe.com/customers/' + user.stripeCustomerId">{{ user.stripeCustomerId }}</a>
          </description-list-entry>

          <description-list-entry title="Subscription active?" :value="user.isActive ? 'yes' : 'no'" />
          <description-list-entry title="Stripe subscription start" :value="user.subscriptionCurrentPeriodStart || '(none)'" />
          <description-list-entry title="Stripe subscription end" :value="user.subscriptionCurrentPeriodEnd || '(none)'" />

          <div class="px-4 py-6 space-x-4">
            <button class="btn h-11" :disabled="!buttonsEnabled || isLoading" @click="addSubscription(1)">
              +1 month
            </button>
            <button class="btn h-11" :disabled="!buttonsEnabled || isLoading" @click="addSubscription(3)">
              +3 months
            </button>
            <button class="btn h-11" :disabled="!buttonsEnabled || isLoading" @click="addSubscription(6)">
              +6 months
            </button>
            <button class="btn h-11" :disabled="!buttonsEnabled || isLoading" @click="addSubscription(12)">
              +12 months
            </button>
            <button class="btn h-11" :disabled="!buttonsEnabled || isLoading" @click="removeSubscription()">
              Remove
            </button>
          </div>
        </dl>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import ky, { HTTPError } from 'ky';
import { post } from '../post';
import { Ref, ref, ShallowRef, shallowRef } from 'vue';
import DescriptionListEntry from '../components/admin/DescriptionListEntry.vue';

const { userIdOrEmail } = defineProps<{
  userIdOrEmail: string;
}>();

const buttonsEnabled = shallowRef(true);
const error: ShallowRef<string | null> = shallowRef('yes');
const isLoading = shallowRef(true);
const user: Ref<any|null> = ref(null);

async function removeSubscription() {
  buttonsEnabled.value = false;
  error.value = null;

  const { json } = await post({
    url: '/api/admin/user/remove-subscription',
    data: {
      id: user.value.id
    }
  });

  buttonsEnabled.value = true;

  if (!json?.success) {
    error.value = json?.error || 'Unknown error';
    return;
  }

  loadUser(); // fire-and-forget
}

async function addSubscription(months: number) {
  buttonsEnabled.value = false;
  error.value = null;

  const { json } = await post({
    url: '/api/admin/user/add-subscription',
    data: {
      months,
      id: user.value.id
    }
  });

  buttonsEnabled.value = true;

  if (!json?.success) {
    error.value = json?.error || 'Unknown error';
    return;
  }

  loadUser(); // fire-and-forget
}

async function loadUser() {
  user.value = null;
  error.value = null;

  let json: any;

  try {
    json = await ky.get('/api/admin/user/' + userIdOrEmail).json();
  } catch (e) {
    if (e instanceof HTTPError) {
      if (e.response.status === 401) {
        error.value = "Unauthorized";
        return;
      }
    }

    error.value = String(e);

  } finally {
    isLoading.value = false;
  }

  if (!json?.success) {
    error.value = json?.error || 'Unknown error';
    user.value = null;
    return;
  }

  user.value = json.user;
}

loadUser();
</script>
