<template>
  <div class="px-1 xs:px-4 py-1 flex gap-x-2 items-center overflow-hidden" :class="isMobile ? 'h-12' : 'h-[100px]'">
    <the-icon v-if="icon" :icon="icon" class="shrink-0" :class="isMobile ? 'mx-0.5' : 'scale-110 mx-3'" :is-bw="isBw" />
    <div class="text-xl mr-auto text-nowrap">
      {{ title }}
    </div>

    <slot />
    <div class="shrink-0 h-11" :class="{ 'px-2': !isMobile }">
      <button-close :disabled="disabled" @click="emit('close')" />
    </div>
  </div>
</template>

<script setup lang="ts">
import TheIcon from './icons/TheIcon.vue';
import ButtonClose from './ButtonClose.vue';

const { isMobile = true } = defineProps<{
  icon?: string;
  title?: string;
  isMobile?: boolean;
  disabled?: boolean;
  isBw?: boolean;
}>();

const emit = defineEmits<{
  close: [void]
}>();
</script>
