<template>
  <div class="flex flex-col h-full bg-blueprint selectable">
    <div
      class="shrink-0 bg-main text-white/60 shadow-lg z-20"
      :class="{ 'toolbar-sm': isMobile }"
    >
      <modal-header :is-mobile="isMobile" icon="UserProfile" is-bw @close="back">
        <div v-if="!isUser" class="mr-auto">
          <div class="hidden xs:inline-block">
            Already subscribed?
          </div>
          <button :disabled="!isButtonEnabled" class="link ml-2" @click="showLogin">Sign&nbsp;In</button>
        </div>

        <template v-else-if="isActive">
          <div class="mr-auto">
            <div class="hidden sm:inline-block">
              Seems you are already subscribed.
            </div>
            <button :disabled="!isButtonEnabled" class="link ml-2" @click="manageSubscription">Manage subscription</button>
          </div>
        </template>

        <div v-else class="mr-auto">
          <div class="hidden sm:inline-block">
            Purchase subscription to enjoy Dehancer Online
          </div>
        </div>
      </modal-header>
    </div>

    <div class="h-full overflow-auto">
      <prices-block class="py-16" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { isUser, isActive } from '../user';
import { goToManageSubscriptionWithButtonEnabled } from '../purchaseSubscriptionHelper';
import { showLogin } from '../auth';
import { isMobile } from '../breakpoints';
import { shallowRef } from 'vue';
import { useRouter } from 'vue-router';
import ModalHeader from '../components/ModalHeader.vue';
import PricesBlock from '../components/PricesBlock.vue';

const router = useRouter();

function back() {
  if (router.currentRoute.value?.query?.i) {
    router.push({ name: 'imagePage', query: { i: router.currentRoute.value.query.i } });
  } else {
    router.push('/');
  }
}

const isButtonEnabled = shallowRef(true);

function manageSubscription() {
  goToManageSubscriptionWithButtonEnabled(isButtonEnabled);
}
</script>
