<template>
  <svg viewBox="0 0 80 67" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="linearGradient-1" x1="50%" y1="80.2001953%" x2="50%" y2="0%">
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="100%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="100%" />
      </linearGradient>
      <path id="path-2" d="M71.9089064,52.0697105 L71.9089064,48.908692 L71.9089064,48.908692 C59.8022597,35.9251683 53.4585389,29.4334065 52.8777442,29.4334065 C52.2969494,29.4334065 45.9513745,33.8942474 33.8410193,42.8159293 C27.7034317,38.5613073 24.3834834,36.4339963 23.8811744,36.4339963 C23.4259569,36.4339963 17.9369913,40.5922282 7.41427754,48.908692 C7.41427754,49.9623648 7.41427754,51.0160377 7.41427754,52.0697105 C7.41427754,55.9357038 10.5482843,59.0697105 14.4142775,59.0697105 L64.9089064,59.0697105 C68.7748997,59.0697105 71.9089064,55.9357038 71.9089064,52.0697105 Z M30.7203746,26.0480367 C32.5706667,26.0480367 34.1476202,25.3879422 35.451235,24.0677531 C36.7548499,22.747564 37.4066573,21.1654326 37.4066573,19.3213589 C37.4066573,18.1269021 37.1070362,17.0215057 36.5077938,16.0051696 C35.9085515,14.9888335 35.1043053,14.1768125 34.095055,13.5691064 C33.0858048,12.9614003 31.9609113,12.6575472 30.7203746,12.6575472 C28.8911086,12.6575472 27.3246681,13.3176418 26.0210533,14.6378309 C24.7174384,15.95802 24.065631,17.519196 24.065631,19.3213589 C24.065631,21.1654326 24.7174384,22.747564 26.0210533,24.0677531 C27.3246681,25.3879422 28.8911086,26.0480367 30.7203746,26.0480367 Z" />
      <linearGradient id="linearGradient-3" x1="50%" y1="80.2001953%" x2="50%" y2="0%">
        <stop stop-color="#FFFFFF" stop-opacity="0" offset="0%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="100%" />
        <stop stop-color="#FFFFFF" stop-opacity="0.5" offset="100%" />
      </linearGradient>
      <path id="path-4" d="M64.6694927,0 L14.5226623,0 C6.50201738,0 0,6.50201738 0,14.5226623 L0,51.9657415 C0,59.9863864 6.50201738,66.4884038 14.5226623,66.4884038 L64.6694927,66.4884038 C72.6901376,66.4884038 79.192155,59.9863864 79.192155,51.9657415 L79.192155,14.5226623 C79.192155,6.50201738 72.6901376,0 64.6694927,0 Z M64.6694927,3.06869556 C70.9953439,3.06869556 76.1234594,8.19681114 76.1234594,14.5226623 L76.1234594,51.9657415 C76.1234594,58.2915927 70.9953439,63.4197082 64.6694927,63.4197082 L14.5226623,63.4197082 C8.19681114,63.4197082 3.06869556,58.2915927 3.06869556,51.9657415 L3.06869556,14.5226623 C3.06869556,8.19681114 8.19681114,3.06869556 14.5226623,3.06869556 L64.6694927,3.06869556 Z" />
    </defs>
    <g id="Dehancer-Online" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Dehancer-Online-Upload" transform="translate(-600, -305)">
        <g id="Dropzone" transform="translate(420, 151)">
          <g id="icnUpload" transform="translate(180.6648, 154.2563)">
            <g id="Shape-Copy-2">
              <use fill="#548FA7" xlink:href="#path-2" />
              <use fill-opacity="0.5" fill="url(#linearGradient-1)" xlink:href="#path-2" />
            </g>
            <g id="Rectangle-Copy-7">
              <use fill="#548FA7" xlink:href="#path-4" />
              <use fill-opacity="0.5" fill="url(#linearGradient-3)" xlink:href="#path-4" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
