<template>
  <transition
    enter-active-class="transition ease-out"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
    appear
  >
    <div v-if="realIsOpen" class="fixed inset-0 z-50 bg-main flex flex-col gap-y-4 justify-center items-center">
      <div>
        <div class="w-16 tall:w-24 mx-auto">
          <Vue3Lottie ref="lottie" :animation-data="logoLoop" :loop="true" @on-loop-complete="onOnLoopComplete" />
        </div>
        <div v-if="message" class="mt-4 tall:mt-8 text-center font-light">
          {{ message }}
        </div>
      </div>

      <div v-if="shouldShowButton" class="text-center tall:bottom-[20vh] tall:fixed">
        <a class="link text-[18px] font-medium" @click="buttonClicked">Okay!</a>
      </div>

      <div v-else-if="progress !== null" class="tall:fixed tall:bottom-[20vh] tall:left-1/2">
        <div class="w-56 tall:-ml-28 rounded-full border border-white/60">
          <div
            class="bg-white p-1.5 leading-none rounded-full"
            :style="`width: ${progress}%`"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, shallowRef, watch, Ref, ShallowRef } from 'vue';
import { Vue3Lottie } from 'vue3-lottie';
import logoLoop from '../assets/logo-loop.json';
import { isOpen, message, progress, shouldShowButton, buttonCallback } from '../lockScreen';

const lottie: Ref<typeof Vue3Lottie | null> = ref(null);
const realIsOpen = shallowRef(false);
const onOnLoopComplete: ShallowRef<((...args: any[]) => any) | undefined> = shallowRef();

watch(shouldShowButton, value => {
  if (value) {
    progress.value = null;
    lottie.value?.goToAndStop(40, true);
  }
});

watch(isOpen, value => {
  if (value) {
    onOnLoopComplete.value = undefined;
    realIsOpen.value = true;
    lottie.value?.play();
    return;
  }

  realIsOpen.value = false;
  message.value = null;
  progress.value = null;
  lottie.value?.stop();
}, { immediate: true });

function buttonClicked() {
  shouldShowButton.value = false;
  realIsOpen.value = false;
  message.value = null;
  progress.value = null;
  lottie.value?.stop();

  if (buttonCallback.value) {
    buttonCallback.value();
    buttonCallback.value = null;
  }
}
</script>
