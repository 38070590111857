<template>
  <tool-bar
    :pane-mode="paneMode!"
    :state="state"
    :is-pane-expanded="isPaneExpanded"
    :is-settings-modal-open="isSettingsModalOpen"
    :is-f-x-modal-open="isFXModalOpen"
    :fit-mode="fitMode"
    :is-original-shown="isOriginalShown"
    @toggle-pane="togglePane"
    @open-settings-modal="toggleSettingsModal"
    @open-f-x-modal="toggleFXModal"
    @toggle-fit="toggleFit"
    @export-image="goToExport"
    @toggle-original="toggleOriginal"
    @toggle-main-menu="toggleMainMenu"
  >
    <modal-settings
      :is-open="isSettingsModalOpen"
      :state="state"
      @close="toggleSettingsModal"
      @update="updateState"
    />

    <modal-f-x
      :is-open="isFXModalOpen"
      :state="state"
      @close="toggleFXModal"
      @update="updateState"
    />
  </tool-bar>

  <scroll-pane
    ref="pane"
    :expanded="isPaneExpanded"
    :items="paneItems"
    :items-total="itemsTotal"
    :items-per-row="itemsPerRow"
    :image-size="imageSize"
    :is-original-shown="isOriginalShown"
    :highlighted-item-id="highlightedItemId"
    :original-url="originalUrl"
    :placeholder-url="placeholderUrl"
    :should-show-preset-titles="paneMode === 'preset' || !isPaneExpanded"
    :preset-id="state.presetId"
    :fit-mode="fitMode"
    :sections="paneMode === 'preset' ? presetSections : undefined"
    @selected="onSelected"
    @scaling="onScaling"
    @contextmenu.prevent
  />
</template>

<script setup lang="ts">
import { ref, reactive, computed, shallowRef, watch, onBeforeUnmount, Ref, Reactive } from 'vue';
import ScrollPane from '../components/ScrollPane.vue';
import ToolBar from '../components/ToolBar.vue';
import ModalSettings, { ModalSettingsStateUpdate } from '../components/ModalSettings.vue';
import ModalFX, { ModalFXStateUpdate } from '../components/ModalFX.vue';
import { requestSmallImages, requestLargeImages, requestSingleLargeImage, ImageArray } from '../panes';
import { ImageSizeStructure, PANES, PaneMode, ScrollPaneFitMode } from '../paneConstants';
import { translateFromColorId, translateFromLightId, transformToColorId, transformToLightId } from '../paneProcessor';
import { useRouter } from 'vue-router';
import { isMobile } from '../breakpoints';
import { presets, presetSections } from '../presets';
import { isActive } from '../user';
import { stateByImageId, type DehancerImageState } from '../stateByImageId';

const router = useRouter();

const hiresScale = computed(() => (isMobile.value ? 0.6 : 1.6));

const { imageId, imageSequence } = defineProps<{
  imageId: string;
  imageSize: ImageSizeStructure;
  imageSequence: number;
  originalUrl: string;
  originalFilename: string;
  placeholderUrl: string;
}>();

const pane: Ref<typeof ScrollPane | null> = ref(null);

let onScaleTimeout: number | null = null;

onBeforeUnmount(() => {
  if (onScaleTimeout) {
    clearTimeout(onScaleTimeout);
    onScaleTimeout = null;
  }
});

const state: Reactive<DehancerImageState> = reactive({
  imageId,
  presetId: undefined,
  tint: 0,
  temperature: 0,
  contrast: 0,
  exposure: 0,
  color_boost: 0,
  is_grain_enabled: false,
  grain: 0,
  is_bloom_enabled: false,
  bloom: 0,
  is_halation_enabled: false,
  halation: 0,
  is_vignette_enabled: false,
  vignette_exposure: 0,
  vignette_size: 0,
  vignette_feather: 0
});

watch(state, () => {
  stateByImageId.value[imageId] = state;
});

const paneItems: Ref<ImageArray | null> = ref(null);
const paneMode: Ref<PaneMode | undefined> = ref();
const isPaneExpanded = ref(false);
const isSettingsModalOpen = ref(false);
const isFXModalOpen = ref(false);
const fitMode: Ref<ScrollPaneFitMode> = ref('contain');
const isOriginalShown = shallowRef(false);

const highlightedItemId = computed(() => {
  if (paneMode.value === 'preset') {
    return state.presetId;
  }
  if (paneMode.value === 'light') {
    return transformToLightId(state.contrast, state.exposure);
  }
  if (paneMode.value === 'color') {
    return transformToColorId(state.tint, state.temperature);
  }
  if (paneMode.value === 'settings') {
    return 0;
  }

  return undefined;
});

const itemsTotal = computed(() => PANES[paneMode.value!]?.itemsTotal || 0); // FIXME why 0? what if value is undefined? what is PANES[] is undefined?
const itemsPerRow = computed(() => PANES[paneMode.value!]?.itemsPerRow);

const onScaling: Ref<(a: number) => void> = ref(() => {});

if (stateByImageId.value[imageId]) {
  Object.assign(state, stateByImageId.value[imageId]);

  setPane('settings');
} else {
  setPane('preset');
}

function setPane(mode: PaneMode | null = null) {
  if (onScaleTimeout) {
    clearTimeout(onScaleTimeout);
    onScaleTimeout = null;
  }

  if (mode === 'settings') {
    paneItems.value = reactive([]);

    requestSingleLargeImage(
      {
        mode: 'settings',
        ...state,
        sequence: imageSequence
      },
      paneItems.value,
      0,
      paneMode.value !== mode // debouncing
    );

    if (paneMode.value !== mode && pane.value) {
      // trick to reset scroll position asynchronously
      setTimeout(() => pane.value!.center(0, false), 0);
    }

    paneMode.value = mode;
    isPaneExpanded.value = false;

  } else if (mode) {
    paneItems.value = requestSmallImages({
      mode,
      ...state,
      sequence: imageSequence
    });

    // one call per pane mode
    onScaleTimeout = setTimeout(() => {
      onScaling.value = scale => {
        if (scale > hiresScale.value) {
          requestLargeImages({
            mode,
            ...state,
            sequence: imageSequence
          });

          onScaling.value = () => {};
        }
      };
    }, 500);

    paneMode.value = mode;
    isPaneExpanded.value = true;
    isSettingsModalOpen.value = false;
    isFXModalOpen.value = false;

  } else {
    requestSingleLargeImage(
      {
        mode: paneMode.value!,
        ...state,
        sequence: imageSequence
      },
      paneItems.value!,
      highlightedItemId.value!,
      true
    );

    onScaling.value = () => {};

    isPaneExpanded.value = false;
  }
}

function togglePane(mode: PaneMode) {
  if (isPaneExpanded.value) {
    setPane(mode);
    pane.value!.center(highlightedItemId.value, true);
    setPane();

  } else {
    isOriginalShown.value = false;
    setPane(mode);
  }
}

function onSelected(id: number) {
  if (paneMode.value === 'settings') {
    // do nothing
    return;
  }

  if (paneMode.value === 'preset') {
    if (!presets.value[id]) {
      console.log('No dehuncer state for preset', id);
      return;
    }

    const localStateOverride: any = {
      ...presets.value[id],
      presetId: id
    };

    if (state.presetId !== null) {
      localStateOverride.exposure = state.exposure;
      localStateOverride.tint = state.tint;
      localStateOverride.temperature = state.temperature;
      localStateOverride.contrast = state.contrast;
      localStateOverride.color_boost = state.color_boost;
    }

    // FIXME make proper types here
    Object.assign(state, localStateOverride);
    setPane();
    return;
  }

  if (paneMode.value === 'light') {
    const { contrast, exposure } = translateFromLightId(id);
    Object.assign(state, { contrast, exposure });
    setPane();
    return;
  }

  if (paneMode.value === 'color') {
    const { tint, temperature } = translateFromColorId(id);
    Object.assign(state, { tint, temperature });
    setPane();
    return;
  }

  console.log('Unknown pane mode', paneMode.value);
}

function toggleFXModal() {
  isOriginalShown.value = false;
  isSettingsModalOpen.value = false;
  isFXModalOpen.value = !isFXModalOpen.value;
  if (isFXModalOpen.value) {
    setPane('settings');
  }
}

function toggleSettingsModal() {
  isOriginalShown.value = false;
  isFXModalOpen.value = false;
  isSettingsModalOpen.value = !isSettingsModalOpen.value;
  if (isSettingsModalOpen.value) {
    setPane('settings');
  }
}

function updateState(value: ModalFXStateUpdate | ModalSettingsStateUpdate) {
  Object.assign(state, value);
  setPane('settings');
}

function toggleFit() {
  fitMode.value = fitMode.value === 'contain' ? 'cover' : 'contain';
}

function toggleOriginal() {
  isOriginalShown.value = !isOriginalShown.value;
}

function toggleMainMenu() {
  isSettingsModalOpen.value = false;
  isFXModalOpen.value = false;
}

function goToExport() {
  if (isActive.value) {
    router.push({
      name: 'exportPage',
      query: {
        i: imageId
      }
    });
  } else {
    router.push({
      name: 'pricesPage',
      query: {
        i: imageId
      }
    });
  }
}
</script>
