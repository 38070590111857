import { post } from './post.js';
import { API_PREFIX } from './constants.js';
import { ShallowRef } from 'vue';

export async function goToManageSubscriptionWithButtonEnabled(isButtonEnabled?: ShallowRef<boolean>) {
  if (isButtonEnabled) {
    isButtonEnabled.value = false;
  }

  // window.dataLayer.push({
  //   event: 'manageSubscription'
  // });

  const { json } = await post({
    url: API_PREFIX + '/billing/get-portal-url',
    data: { }
  });

  if (!json?.success) {
    if (isButtonEnabled) {
      isButtonEnabled.value = true;
    }
    alert(json?.error || '(Unknown error)');
    return;
  }

  window.location.href = json.url;
}
