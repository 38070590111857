<template>
  <div class="px-4 xs:px-10 mx-auto max-w-5xl">
    <div v-if="isLoading" class="mb-6 px-9 py-8 border border-transparent bg-main rounded-[18px] bg-no-repeat bg-[top_64px_right]">
      Loading prices...
    </div>

    <div v-else-if="isError" class="mb-6 px-9 py-8 border border-transparent bg-main rounded-[18px] bg-no-repeat bg-[top_64px_right]">
      Error loading prices.
    </div>

    <div v-else class="isolate mx-auto grid max-w-sm grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
      <div
        v-for="tier in tiers"
        :key="tier.id"
        class="flex flex-col justify-start px-9 py-8 border border-transparent bg-main rounded-[18px] bg-no-repeat bg-[top_64px_right]"
        :style="
          tier.isLimitedOffer ? `background-image: url('${tShirtUrl}'); background-size: 50%;` : null
        "
      >
        <div
          class="flex items-start justify-between gap-x-4 font-bold min-h-16"
          :class="{
            'text-alert': tier.isMostPopular,
            'text-accent': tier.isLimitedOffer
          }"
        >
          <div class="text-2xl text-balance" v-html="tier.name" />
          <div class="text-[32px] leading-[30px]">{{ tier.priceHr }}</div>
        </div>

        <div
          class="font-medium min-h-16"
          :class="{
            'text-alert': tier.isMostPopular,
            'text-accent': tier.isLimitedOffer
          }"
        >
          <div v-for="(description, key) in tier.descriptions" :key="key" v-html="description" />
        </div>

        <div class="font-medium text-[20px] leading-[28px] min-h-28">
          <div v-for="feature in tier.mainFeatures" :key="feature" v-html="feature" />
        </div>

        <div class="text-[#E7E1D7] mt-5 mb-10">
          <div v-for="feature in tier.features" :key="feature">
            {{ feature }}
          </div>
        </div>

        <form v-if="!isActive" class="w-full relative" @submit.prevent="purchase(tier)">
          <div v-if="showPromoCodeErrorForTierId === tier.id" class="text-alert mt-2 absolute -top-8 inset-x-0 text-center">
            Invalid promo code :-(
          </div>

          <input
            v-model="promoCode[tier.id]"
            class="w-full mt-1 mb-3 rounded-xl text-center bg-black p-1.5 placeholder:text-[#9F9A92] outline-none border"
            :class="showPromoCodeErrorForTierId === tier.id ? 'border-alert text-alert' : 'border-black focus-visible:border-pale text-[#FFFAF1]'"
            type="text"
            placeholder="Promo code"
            :disabled="!isButtonEnabled"
          >

          <button
            type="submit"
            class="w-full btn-sm text-lg py-0.5 font-medium"
            :class="tier.isLimitedOffer ? 'btn-orange' : null"
            :disabled="!isButtonEnabled"
          >
            <template v-if="!tier.isTrialAllowed">
              Buy Limited Kit
            </template>
            <template v-else-if="isUser && didUseTrial">
              Purchase subscription
            </template>
            <template v-else>
              Start {{ trialDurationDays }}-days free trial
            </template>
          </button>
        </form>
      </div>
    </div>

    <div class="mx-auto max-w-7xl px-5 mt-2 text-xs font-light opacity-70">
      <sup>1</sup> exports are rate-limited<br>
      <sup>2</sup> subject to availability; shipping into the majority of the world included.
    </div>

    <ol class="list-decimal pl-5 space-y-4 mt-10 mb-10">
      <li v-for="faq in faqs" :key="faq[0]" class="leading-[1.65rem]">
        {{ faq[0] }}
        <div class="text-pale leading-6">
          {{ faq[1] }}
        </div>
      </li>
    </ol>
  </div>
</template>

<script setup lang="ts">
import { post } from '../post';
import { API_PREFIX } from '../constants';
import { didUseTrial, isUser, isActive } from '../user';
import { showLoginPageAndPurchase } from '../auth';
import { ShallowRef, shallowRef } from 'vue';
import { isLoading, isError, trialDurationDays, tiers, load } from '../priceTiers';
import faqs from '../faqs.json';
import tShirtUrl from '../assets/t-shirt.webp';
import { type PriceTier } from '../../../backend/src/lib/PriceTiersTypes.js';

load();

const isButtonEnabled = shallowRef(true);
const showPromoCodeErrorForTierId: ShallowRef<string | null> = shallowRef(null);
const promoCode: ShallowRef<Record<string, any>> = shallowRef({});

async function purchase(tier: PriceTier) {
  isButtonEnabled.value = false;
  showPromoCodeErrorForTierId.value = null;

  const promoCodeNormalized = (promoCode.value?.[tier.id] || '').trim();

  if (!isUser.value) {
    if (promoCodeNormalized) {
      const { json } = await post({
        url: API_PREFIX + '/billing/check-promo-code',
        data: {
          promoCode: promoCodeNormalized
        }
      });

      if (!json?.success) {
        isButtonEnabled.value = true;
        showPromoCodeErrorForTierId.value = tier.id;
        return;
      }
    }

    isButtonEnabled.value = true;

    showLoginPageAndPurchase({
      tierId: tier.id,
      promoCode: promoCodeNormalized
    });

    return;
  }

  const { json } = await post({
    url: API_PREFIX + '/billing/purchase',
    data: {
      promoCode: promoCodeNormalized,
      tierId: tier.id
    }
  });

  if (!json?.success) {
    isButtonEnabled.value = true;

    if (json?.isInvalidPromoCode) {
      showPromoCodeErrorForTierId.value = tier.id;
      return;
    }

    alert(json?.error || '(Unknown error)');
    return;
  }

  window.location.href = json.url;
}
</script>
