import { Boundaries, Size } from '../typings';

export interface DefaultBoundariesParams {
	cropper: HTMLElement;
	imageSize: Size;
}

export function fillBoundaries({ cropper }: DefaultBoundariesParams): Boundaries {
	return {
		width: cropper.clientWidth,
		height: cropper.clientHeight,
	};
}
