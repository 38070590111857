import ky from 'ky';
import { ShallowRef, shallowRef } from 'vue';
import { API_PREFIX } from './constants.js';
import { type Preset, type PresetSections } from '../../backend/src/lib/PresetsTypes.js';

export const presets: ShallowRef<Preset[]> = shallowRef([]);
export const presetSections: ShallowRef<PresetSections> = shallowRef({});

export async function fetchPresets() {
  const json: any = await ky.get(API_PREFIX + '/presets').json().catch(() => null);
  if (!json?.success) {
    return false;
  }

  presets.value = json.presets as Preset[];
  presetSections.value = json.presetSections as PresetSections;

  return true;
}
