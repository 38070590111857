import { shallowRef, ref, Ref } from 'vue';
import ky from 'ky';
import { API_PREFIX } from './constants.js';
import { type PriceTier } from '../../backend/src/lib/PriceTiersTypes.js';

export const isLoading = shallowRef(true);
export const isError = shallowRef(false);

export const trialDurationDays = shallowRef(0);
export const tiers: Ref<PriceTier[]> = ref([]);

export async function load() {
  isError.value = false;
  isLoading.value = true;

  const json: any = await ky.get(API_PREFIX + '/price-tiers').json().catch(() => null);

  isLoading.value = false;

  if (!json?.success) {
    isError.value = true;
    return;
  }

  tiers.value = json.tiers as PriceTier[];
  trialDurationDays.value = json.trialDurationDays;
}
