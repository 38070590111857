<template>
  <div class="flex items-center justify-center h-10 lg:h-[50px] cursor-pointer rounded-[14px] pb-1 lg:pb-0" :class="{ 'bg-black/40': isActive }" @click="toogleActive">
    <the-icon icon="Angle" class="size-8 ml-1.5" />

    <form @submit.prevent="applyAngleInput">
      <input
        ref="input"
        v-model.number="actualAngle"
        class="w-[50px] lg:w-[75px] text-right text-sm lg:text-xl leading-none mt-1.5 text-info pr-1 bg-transparent outline-none -ml-3"
        :class="isActive ? '' : 'hidden'"
      >
    </form>
    <div
      v-if="!isActive"
      class="w-[50px] lg:w-[75px] text-right text-sm lg:text-xl leading-none mt-1.5 text-info pr-1 bg-transparent outline-none -ml-3"
    >
      {{ angle.toFixed(1) }}
    </div>

    <div class="w-0 h-2 relative mr-4">
      <div class="absolute top-0 -left-0.5 size-1 border border-info rounded-full" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, Ref, ref, shallowRef, watch } from 'vue';
import TheIcon from './icons/TheIcon.vue';
import { onClickOutside } from '@vueuse/core';

const { modelValue = 0 } = defineProps<{
  modelValue: number;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: number]
}>();

const input: Ref<HTMLInputElement | null> = ref(null);
const isActive = shallowRef(false);
const actualAngle = shallowRef(modelValue);

watch(() => modelValue, value => actualAngle.value = parseFloat(value.toFixed(1)), { immediate: true });

const angle = computed({
  get: () => {
    const _value = Number(actualAngle.value);
    if (Number.isNaN(_value)) {
      return 0;
    }
    return Math.floor(actualAngle.value * 10) / 10;
  },

  set: (value: number) => actualAngle.value = value
});

function applyAngleInput() {
  isActive.value = false;

  let _value = Number(actualAngle.value);
  if (Number.isNaN(_value)) {
    actualAngle.value = modelValue;
    emit('update:modelValue', actualAngle.value);
    return;
  }

  while (_value >= 360) {
    _value -= 360;
  }
  while (_value <= -360) {
    _value += 360;
  }
  emit('update:modelValue', _value);

  actualAngle.value = _value;
}

onClickOutside(input, applyAngleInput);

function toogleActive() {
  isActive.value = !isActive.value;

  if (!isActive.value) {
    return;
  }

  setTimeout(() => {
    input.value!.focus();
    input.value!.setSelectionRange(0, input.value!.value.length);
  }, 0);
}

/*
// this is not used anymore as we accept any input and validate afterwards
function onlyDigits(e) {
  const nextVal = e.target.value.substring(0, e.target.selectionStart)
    + (e.data ?? '')
    + e.target.value.substring(e.target.selectionEnd);

  // I don't trust eslint on this one
  if (!/^[\d\-]{1}[\d\.]*$/.test(nextVal)) { // eslint-disable-line no-useless-escape
    e.preventDefault();
  }

  if (/\..*\./.test(nextVal)) {
    e.preventDefault();
  }
}
*/
</script>
