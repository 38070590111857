<template>
  <div
    v-if="isOpen"
    ref="draggable"
    class="absolute overflow-hidden bg-main text-left align-middle shadow-xl z-10 cursor-move"
    :class="isMobile ? 'w-full' : 'min-w-max rounded-2xl'"
    :style="isMobile ? mobileStyle : style"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, Ref } from 'vue';
import { useDraggable, useParentElement } from '@vueuse/core';
import { isMobile } from '../breakpoints';

const { side = 'right' } = defineProps<{
  isOpen: boolean;
  side?: 'left' | 'right';
}>();

const draggable: Ref<any | null> = ref(null);
const parent = useParentElement();
const initialValue = ref({ x: 10, y: 10 });

if (side === 'right') {
  // Set initial position to right
  const unwatch = watch([ draggable, parent ], () => {
    if (!parent.value || !draggable.value) {
      return;
    }

    const { width } = parent.value.getBoundingClientRect();
    const { width: draggableWidth } = draggable.value.getBoundingClientRect();

    initialValue.value.x = width - draggableWidth - 10;
    unwatch();
  });
}

const { style } = useDraggable(draggable, {
  initialValue: initialValue.value,
  containerElement: useParentElement(),
  capture: false
});

const mobileStyle = {
  top: 0
};
</script>
