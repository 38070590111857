<template>
  <div ref="dropzone" class="w-full h-full flex flex-col relative bg-blueprint">
    <tool-bar is-all-disabled>
      <div class="flex flex-col items-center">
        <div class="w-full lg:max-w-5xl px-4 xs:px-10 my-10 xs:my-20">
          <div class="w-full max-w-sm lg:max-w-none mx-auto my-10">
            <file-uploader :drop-target="dropzone!" :is-trial="!isActive" :wide="!isActive" @uploaded="onFileUploaded" />
          </div>
        </div>

        <prices-block v-if="!isActive" class="mb-20" />
      </div>
    </tool-bar>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref } from 'vue';
import FileUploader from '../components/FileUploader.vue';
import { useRouter } from 'vue-router';
import ToolBar from '../components/ToolBar.vue';
import PricesBlock from '../components/PricesBlock.vue';
import { isActive } from '../user';

const router = useRouter();
const dropzone: Ref<HTMLElement | null> = ref(null);

function onFileUploaded(imageId: string) {
  router.push({ name: 'imagePage', query: { i: imageId } });
}
</script>
