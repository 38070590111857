<template>
  <div v-if="lastImage">
    <div class="px-8 pt-10 flex gap-x-4 text-pale">
      <div class="w-24 h-24 relative shrink-0">
        <img v-if="lastImage.thumbnail" :src="'data:image/png;base64,' + lastImage.thumbnail" class="w-full h-full object-cover rounded-xl">
        <div v-else class="flex justify-center items-center w-full h-full bg-neutral-600 rounded-xl">
          <icon-image class="size-12 saturate-0" />
        </div>
      </div>
      <div class="grow space-y-3">
        <div class="text-xs font-extralight">
          {{ lastImage.title }}
        </div>
        <div class="text-xs font-extralight">
          Edited at {{ lastImageUpdatedAtHr }}
        </div>
      </div>
    </div>
    <div class="text-center px-8 mt-4">
      <router-link :to="{ name: 'imagePage', query: { i: lastImage.imageId } }" class="btn-sm w-full max-w-60">Open last photo</router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import IconImage from './icons/IconImage.vue';
import { formatDateLocale } from '../formatters';
import { lastImage } from '../lastImage';

const lastImageUpdatedAtHr = computed(() => {
  if (!lastImage.value) {
    return '';
  }

  return formatDateLocale(Number(lastImage.value.updatedAt));
});
</script>
