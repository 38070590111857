import { ShallowRef, shallowRef } from 'vue';
import { API_PREFIX } from './constants.js';
import { NewsEntry } from '../../backend/src/commonTypes.js';
import ky from 'ky';

export const isOpen = shallowRef(false);

export const news: ShallowRef<NewsEntry[] | null> = shallowRef(null);

export async function fetch(top?: boolean) {
  let url = '/news/get';
  if (top) {
    url += '?top=1';
  }
  const json = await ky.get(API_PREFIX + url).json().catch(() => null) as any;
  if (!json?.success) {
    return false;
  }

  news.value = json.news;

  if (news.value!.length > 0) {
    isOpen.value = true;
  }

  return true;
}

export async function markAsRead() {
  await ky.post(API_PREFIX + '/news/mark', {
    json: {
      id: news.value![0].id
    }
  });
}
