import ChunkUploadHandler from 'vue-upload-component/src/chunk/ChunkUploadHandler';

const sendRequest = (xhr, body) => new Promise((resolve, reject) => {
  xhr.onload = () => {
    if (xhr.status >= 200 && xhr.status < 300) {
      resolve(xhr.getResponseHeader('etag'));
    } else {
      reject(xhr.response);
    }
  };
  xhr.onerror = e => reject(e);
  xhr.send(body);
});

export default class CustomChunkUploadHandler extends ChunkUploadHandler {
  constructor(file, options) {
    super(file, options);
    this.chunkSize = options.chunkSize;
  }

  start() {
    this.createChunks();
    this.startChunking();
  }

  createChunks() {
    this.chunks = [];

    for (let i = 0; i < this.options.urls.length; i++) {
      const start = i * this.chunkSize;

      let end = Math.min(this.fileSize, start + this.chunkSize);

      if (i === this.options.urls.length - 1) { // last
        end = this.fileSize;
      }

      this.chunks.push({
        blob: this.file.file.slice(start, end),
        active: false,
        retries: this.maxRetries,
        partNumber: i + 1,
        url: this.options.urls[i]
      });
    }
  }

  uploadChunk(chunk) {
    chunk.active = true;
    this.updateFileProgress();

    chunk.xhr = new XMLHttpRequest();
    chunk.xhr.open('PUT', chunk.url, true);

    chunk.xhr.upload.addEventListener('progress', evt => {
      if (evt.lengthComputable) {
        chunk.progress = Math.round(evt.loaded / evt.total * 100);
        this.updateFileProgress();
      }
    }, false);

    sendRequest(chunk.xhr, chunk.blob)
      .then(res => {
        chunk.active = false;
        chunk.uploaded = true;
        chunk.etag = res;

        this.uploadNextChunk();
      })
      .catch(e => { // eslint-disable-line consistent-return
        chunk.active = false;
        if (chunk.retries-- <= 0) {
          this.stopChunks();
          return this.reject(e);
        }

        this.uploadNextChunk();
      });
  }

  finish() {
    this.updateFileProgress();
    this.stopSpeedCalc();

    this.file.etags = [];
    for (let i = 0; i < this.chunks.length; i++) {
      this.file.etags[this.chunks[i].partNumber - 1] = this.chunks[i].etag;
    }

    this.resolve();
  }

  updateFileProgress() {
    if (this.progress > this.file.progress) {
      this.file.progress = this.progress;
    }
  }
}
