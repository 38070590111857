import { ShallowRef, shallowRef } from 'vue';

export const isOpen = shallowRef(false);
export const title: ShallowRef<string | null> = shallowRef(null);
export const subtitle: ShallowRef<string | null> = shallowRef(null);
export const explanation: ShallowRef<string | null> = shallowRef(null);

export function open(_title:string | null = null, _subtitle:string | null = null, _explanation:string | null = null) {
  title.value = _title;
  subtitle.value = _subtitle;
  explanation.value = _explanation;
  isOpen.value = true;
}

export function close() {
  isOpen.value = false;
}
