<template>
  <scroll-pane-wrapper
    ref="wrapper"
    v-slot="{ state }"
    :main-image-x="highlightedImageX"
    :main-image-y="highlightedImageY"
    :pane-size-x="paneSizeX"
    :pane-size-y="paneSizeY"
    :image-size-x="thumbnailWidth"
    :image-size-y="thumbnailHeight"
    :image-mode="fitMode"
    :disabled="!expanded"
    @scaling="onScaling"
  >
    <div v-if="itemsTotal" class="grid grid-flow-row" :style="paneStyle">
      <template v-for="i in itemsTotal" :key="i">
        <div
          v-if="sections?.[ i - 1 ]"
          class="col-start-1 flex items-end leading-none text-pale"
          :class="{ 'opacity-0': !expanded }"
          :style="{
            gridColumn: `span ${itemsPerRow}`,
            fontSize: (25 / state.scale ** 0.5) + 'px',
            height: SECTION_TITLE_HEIGHT + 'px'
          }"
        >
          {{ sections[ i - 1 ] }}
        </div>

        <scroll-pane-item
          v-if="expanded || (highlightedItemId == i - 1) || itemsTotal == 1"
          :title="titleById(i - 1)"
          :placeholder-url="placeholderUrl"
          :image-url="isOriginalShown ? originalUrl : items[i - 1]"
          :image-width="thumbnailWidth"
          :image-height="thumbnailHeight"
          :is-highlighted="isHighlighted(i - 1)"
          :is-current-preset="expanded && shouldShowPresetTitles && isHighlighted(i - 1)"
          :is-pane-expanded="expanded"
          :scale="state.scale"
          :translate-x="state.translateX"
          :translate-y="state.translateY"
          @clicked="onItemClicked(i - 1)"
        />

        <div
          v-else :style="{
            height: thumbnailHeight + 'px',
            width: thumbnailWidth + 'px'
          }"
        />
      </template>
    </div>
  </scroll-pane-wrapper>
</template>

<script setup lang="ts">
import { ref, computed, watch, shallowRef, Ref, ComputedRef } from 'vue';
import ScrollPaneWrapper from './ScrollPaneWrapper.vue';
import ScrollPaneItem from './ScrollPaneItem.vue';
import { presets } from '../presets';
import { ImageSizeStructure, PinchScrollZoomEmitData, ScrollPaneFitMode } from '../paneConstants';
import { PresetSections } from '../../../backend/src/lib/PresetsTypes.js';

const {
  expanded = false,
  itemsTotal = 0,
  itemsPerRow = 0,
  imageSize,
  highlightedItemId,
  shouldShowPresetTitles = false,
  presetId,
  fitMode = 'contain',
  isOriginalShown,
  sections
} = defineProps<{
  expanded: boolean;
  itemsTotal: number;
  itemsPerRow: number;
  highlightedItemId?: number;
  imageSize: ImageSizeStructure;
  placeholderUrl: string;
  originalUrl: string | null;
  shouldShowPresetTitles: boolean;
  presetId?: number;
  fitMode: ScrollPaneFitMode;
  isOriginalShown: boolean;

  items: any; // FIXME any
  sections?: PresetSections
}>();

const spacing = 12;
const paddingX = 48;
const paddingY = 48;

const THUMBNAIL_SIZE_MAX = 300;

const SECTION_TITLE_HEIGHT = 80;

const thumbnailWidth = shallowRef(0);
const thumbnailHeight = shallowRef(0);

const wrapper: Ref<typeof ScrollPaneWrapper | null> = ref(null);

watch(() => expanded, value => {
  if (!value) {
    return;
  }

  wrapper.value!.reset();
}, { flush: 'post' });

watch(() => imageSize, ({ width, height }) => {
  if (width === null || height === null) {
    return;
  }

  if (width > height) {
    thumbnailWidth.value = THUMBNAIL_SIZE_MAX;
    thumbnailHeight.value = THUMBNAIL_SIZE_MAX / (width / height);

  } else {
    thumbnailHeight.value = THUMBNAIL_SIZE_MAX;
    thumbnailWidth.value = THUMBNAIL_SIZE_MAX / (height / width);
  }
}, { immediate: true });

const sectionKeys: ComputedRef<number[]>  = computed(() => Object.keys(sections ?? {}).map(a => parseInt(a, 10)));

const itemsPerColumn = computed(() => {
  if (sectionKeys.value.length === 0) {
    return Math.ceil(itemsTotal / itemsPerRow);
  }

  let perColumn = sectionKeys.value.length;
  for (let i = 1; i < sectionKeys.value.length; i++) {
    const _items = sectionKeys.value[i] - sectionKeys.value[i - 1];
    perColumn += Math.ceil(_items / itemsPerRow);
  }

  perColumn += Math.ceil((itemsTotal - sectionKeys.value[sectionKeys.value.length - 1]) / itemsPerRow);

  return perColumn - sectionKeys.value.length;
});

const highlightedImageX = computed(() => {
  if (highlightedItemId === null || highlightedItemId === undefined) {
    return undefined;
  }
  const { x } = coordinatesById(highlightedItemId);
  return x;
});

const highlightedImageY = computed(() => {
  if (highlightedItemId === null || highlightedItemId === undefined) {
    return undefined;
  }
  const { y } = coordinatesById(highlightedItemId);
  return y;
});

defineExpose({ center });

const emit = defineEmits<{
  selected: [ number ];
  scaling: [ number ];
}>();

const paneSizeX = computed(() => thumbnailWidth.value * itemsPerRow + spacing * (itemsPerRow - 1) + paddingX * 2);
const paneSizeY = computed(() => thumbnailHeight.value * itemsPerColumn.value
  + spacing * (itemsPerColumn.value - 1)
  + paddingY * 2
  + sectionKeys.value.length * (SECTION_TITLE_HEIGHT + spacing));

const paneStyle = computed(() => `
  width: ${paneSizeX.value}px;
  height: ${paneSizeY.value}px;
  padding: ${paddingY}px ${paddingX}px;
  grid-template-columns: repeat(${itemsPerRow},1fr);
  grid-template-rows: repeat(${itemsPerColumn.value + sectionKeys.value.length},1fr);
  gap: ${spacing}px;
`);

function coordinatesById(id: number): { x: number; y: number } {
  let row;
  let col;
  let sectionCount = 0;

  if (sectionKeys.value.length === 0) {
    row = Math.floor(id / itemsPerRow);
    col = (id % itemsPerRow);

  } else {
    row = 0;

    for (let i = 1; i <= sectionKeys.value.length; i++) {
      if (id < sectionKeys.value[i] || i === sectionKeys.value.length) {
        row += Math.floor((id - sectionKeys.value[i - 1]) / itemsPerRow);
        col = (id - sectionKeys.value[i - 1]) % itemsPerRow;
        sectionCount = i;
        break;
      }

      row += Math.ceil((sectionKeys.value[i] - sectionKeys.value[i - 1]) / itemsPerRow);
      sectionCount = i;
    }
  }

  const x = col! * (thumbnailWidth.value + spacing) + paddingX + thumbnailWidth.value / 2;
  const y = row * (thumbnailHeight.value + spacing)
    + paddingY
    + thumbnailHeight.value / 2
    + sectionCount * (SECTION_TITLE_HEIGHT + spacing);

  return { x, y };
}

function isHighlighted(id: number): boolean {
  return highlightedItemId === id;
}

function titleById(id: number): string {
  if (!shouldShowPresetTitles) {
    return '';
  }

  if (isOriginalShown) {
    return 'Original';
  }

  const _id = expanded ? id : presetId;

  if (!presets.value?.[_id!]) {
    return '(unknown)';
  }

  return presets.value?.[_id!].caption || '';
}

function center(id: number, animate = true) {
  wrapper.value!.center(coordinatesById(id), animate);
}

async function onItemClicked(id: number) {
  await wrapper.value!.center(coordinatesById(id));
  emit('selected', id);
}

function onScaling(e: PinchScrollZoomEmitData) {
  emit('scaling', e.scale);
}
</script>
